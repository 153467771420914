import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import authReducer from "../features/auth/authSlice";
import { eventApi } from "../features/events/eventSlice";
import { userApi } from "../features/user/userSlice";
import { resetPasswordApi } from "../features/auth/resetPasswordSlice";
import { reservationApi } from "../features/reservations/reservationSlice";
import {connectionApi} from "../features/connection/connectionSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [eventApi.reducerPath]: eventApi.reducer,
    [resetPasswordApi.reducerPath]: resetPasswordApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [reservationApi.reducerPath]: reservationApi.reducer,
    [connectionApi.reducerPath]: connectionApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(eventApi.middleware)
      .concat(resetPasswordApi.middleware)
      .concat(userApi.middleware)
      .concat(reservationApi.middleware)
      .concat(connectionApi.middleware),
});

setupListeners(store.dispatch);

import React from "react";
import { Input } from "antd";
const { TextArea } = Input;

export const CLInput = (props) => {
  return <Input {...props} />;
};
export const CLInputPassword = (props) => {
  return <Input.Password {...props} />;
};
export const CLTextArea = (props) => {
  return <TextArea {...props} />;
};

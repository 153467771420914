import { CLButton } from "components/Button";
import React, { useState } from "react";
import RightCircleOutlined from "@ant-design/icons/RightCircleOutlined";
import { CLInput } from "components/Input";
import { Checkbox } from "antd";

const Organiserflow = () => {
  const [stepsAt, setstepsAt] = useState(1);
  return (
    <div className="w-[300px] md:w-[800px] mt-5">
      {stepsAt === 1 && (
        <section className="main">
          <div className="text-xl font-bold text-primary-main mb-3">
            Try out Clublink. Its free for small events
          </div>
          <div className="mb-2 flex items-center gap-3">
            <RightCircleOutlined />
            Single platform for various types of events form
          </div>
          <div className="mb-2 flex items-center gap-3">
            <RightCircleOutlined />
            Attendees connect while maintaining privacy
          </div>
          <div className="mb-2 flex items-center gap-3">
            <RightCircleOutlined />
            Build your community simultaneously
          </div>
        </section>
      )}
      {stepsAt === 2 && (
        <section className="Checkin">
          {" "}
          <section className="main flex gap-5 mb-10">
            <div
              style={{
                border: "2px solid #267c93",
                padding: "10px",
              }}
            >
              <div className="md:text-xl font-bold text-primary-main mb-3 text-center">
                Create Event
              </div>
              <div className="md:p-[60px]">
                <img src="/images/landing/add-event.svg" alt="check" />
              </div>
            </div>
            <div
              style={{
                border: "2px solid #267c93",
                padding: "10px",
              }}
            >
              <div className="md:text-xl  font-bold text-primary-main mb-3 text-center">
                Run Event
              </div>
              <div className="md:p-[60px]">
                <img src="/images/landing/event-planner.svg" alt="check" />
              </div>
            </div>
            <div
              style={{
                border: "2px solid #267c93",
                padding: "10px",
              }}
            >
              <div className="md:text-xl  font-bold text-primary-main mb-3 text-center">
                Engage
              </div>
              <div className="md:p-[60px]">
                <img src="/images/landing/web-browser.svg" alt="check" />
              </div>
            </div>
          </section>
        </section>
      )}
      {stepsAt === 3 && (
        <section className="Connect">
          <div className="text-xl font-bold text-primary-main mb-3">
            Try it out yourself
          </div>
          <div className="mb-2 flex items-center gap-3">
            <RightCircleOutlined />
            Register on Juznow.com
          </div>
          <div className="mb-2 flex items-center gap-3">
            <RightCircleOutlined />
            Create an publish an event on Clublink
          </div>
          <div className="mb-2 flex items-center gap-3">
            <RightCircleOutlined />
            Take it for a test drive with a couple of test users.
          </div>
          <div className="mb-2 flex items-center gap-5 text-lg text-primary-main">
            You can also request a demo
          </div>
        </section>
      )}
      {stepsAt === 4 && (
        <section className="Done">
          <div className="text-center mb-3 text-2xl text-primary-main font-bold">
            Request a demo
          </div>
          <div className="text-center">Send an email to support@juznow.com</div>
        </section>
      )}
      <div className="mt-5">
        <div
          className={`flex ${
            [1].includes(stepsAt) ? `justify-end` : `justify-between`
          }`}
        >
          {![1, 4].includes(stepsAt) && (
            <CLButton
              className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 md:w-[150px] w-[100px]"
              onClick={() => {
                setstepsAt(stepsAt - 1);
              }}
            >
              Prev
            </CLButton>
          )}
          {![4].includes(stepsAt) && (
            <CLButton
              className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 md:w-[150px] w-[100px]"
              onClick={() => {
                setstepsAt(stepsAt + 1);
              }}
            >
              Next
            </CLButton>
          )}
        </div>
        <div className="flex justify-center">
          {" "}
          {[4].includes(stepsAt) && (
            <CLButton
              className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 md:w-[150px] w-[100px]"
              onClick={() => {
                setstepsAt(1);
              }}
            >
              Done
            </CLButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Organiserflow;

import Layout from "components/Layout";
import React, { Fragment, useEffect, useState } from "react";
import { useGetOrgUpcommingEventsQuery } from "../../features/events/eventSlice";
import EventCard from "components/EventCard";
import { CLInput } from "components/Input";
import { SearchOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { JuzNowInfo } from "components/Info";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OrganiserEventCard from "components/EventCard/OrganiserEventCard";
import { Tabs } from "antd";

const UpcommingEventsOrg = () => {
  const Navigate = useNavigate();
  const { data, error, isLoading, refetch } = useGetOrgUpcommingEventsQuery();
  const [RnderData, setRnderData] = useState(data);
  const { pathname } = useLocation();
  useEffect(() => {
    setRnderData(data);
  }, [data]);
  useEffect(() => {
    refetch();
  }, []);
  const items = [
    {
      key: "1",
      label: "Upcoming",
    },
    {
      key: "2",
      label: "Past events",
    },
  ];
  const onChange = (key) => {
    if (key === "1") {
      Navigate(`/organiser/upcoming`);
    } else if (key === "2") {
      Navigate(`/organiser/past`);
    }
  };
  return (
    <Layout>
      <article className="p-10">
        <Tabs
          centered
          size={"large"}
          defaultActiveKey={"1"}
          items={items}
          onChange={onChange}
          tabBarStyle={{ fontWeight: "500" }}
        />
      </article>
      <section className="md:p-10 p-2">
        {isLoading && "Loading..."}
        {error && "Error"}
        {pathname === "/events-attended" && (
          <section className="flex gap-10 flex-wrap">
            {RnderData && RnderData.map((event) => <EventCard event={event} />)}
          </section>
        )}
        {(pathname === "/organiser/upcoming" ||
          pathname === "/organiser/past") && (
          <section className="flex gap-10 flex-wrap">
            {RnderData &&
              RnderData.map((event) => <OrganiserEventCard event={event} />)}
          </section>
        )}
        {RnderData?.length === 0 && !isLoading && !error && (
          <JuzNowInfo keyFor="upcomming" />
        )}
      </section>
    </Layout>
  );
};

export default UpcommingEventsOrg;

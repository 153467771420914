import React, { useEffect, useState } from "react";
import { useGetUserByScanQuery } from "features/user/userSlice";
import { useSendMatchRequestMutation } from "features/connection/connectionSlice";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "components/Layout";
import { CloseCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { CLImage } from "components/Image";
import { CLNotification } from "components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { scanedUser } from "features/auth/authSlice";
import { useGetEventResponseDataQuery } from "features/events/eventSlice";

const ScanUser = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid, id } = useParams();
  const { data, error, isLoading, isSuccess, refetch } = useGetUserByScanQuery({
    uid,
    id,
  });
  const {
    data: isRSData,
    error: isRSError,
    isLoading: isRSLoading,
    isSuccess: isRSSuccess,
    refetch: isRSRefetch,
  } = useGetEventResponseDataQuery({
    id,
    uid: user?.id,
  });
  const [
    sendRequest,
    { isLoading: isSending, isSuccess: isSent, error: isError, data: isData },
  ] = useSendMatchRequestMutation();

  useEffect(() => {
    if (isSent || isError) {
      CLNotification({
        type: "success",
        description: isError?.data?.message ?? isData?.message,
      });
      setTimeout(() => {
        navigate(`/event/${id}/event-dashboard`);
      }, 2000);
    }
  }, [isSent, isError]);
  useEffect(() => {
    refetch({
      uid,
      id,
    });
    isRSRefetch({
      id,
      uid: user?.id,
    });
  }, [uid]);
  useEffect(() => {
    if (error?.status === 400) {
      navigate("/");
    }
  }, [error]);

  const [markResp, setmarkResp] = useState(null);
  return (
    <div>
      <header className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 md:px-11 overscroll-none items-center">
        <div className="flex items-center">
          <div className="flex items-center">
            <img
              src="/images/Event/backBtn.svg"
              className="cursor-pointer mr-2"
              alt="back"
              onClick={() => {
                navigate(`/event/${id}/event-dashboard`);
              }}
            />
          </div>
          <div className="font-bold">Matching Session</div>
        </div>
      </header>
      <section className="p-10">
        {isLoading && "Loading..."}
        {error && (
          <div className="flex justify-center">{error.data.message}</div>
        )}
        {data && (
          <div className="flex flex-col md:flex-row gap-5">
            <section className="md:basis-1/5">
              <div className="shadow-md h-[600px] bg-cover bg-center mb-10 rounded-lg">
                {/* <div className="p-2 flex items-center gap-16">
                  <img
                    src="/images/Event/backBtn.svg"
                    className="cursor-pointer"
                    alt="back"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <span className="text-xl">Matching Session</span>
                </div> */}
                <section className="min-h-[128px] border rounded-lg shadow-md border-slate-300 m-5">
                  <div className="text-xl font-medium">
                    <div className="p-2">
                      <CLImage
                        width={"100%"}
                        height={120}
                        src={
                          data?.profile_image?.length > 0
                            ? data?.profile_image
                            : "/images/avatarDummy.svg"
                        }
                      />
                    </div>
                  </div>
                </section>
                <div className="text-xl font-bold mb-10 text-center">
                  {data?.userAlias}
                </div>
                <div className="px-5 flex justify-between">
                  <CloseCircleFilled
                    style={{
                      color: `${
                        (isRSData?.ResponseList?.find(
                          (d) => d.Matched._id === uid
                        )?.userResponse?.status === "rejected" ||
                          markResp === "rejected") &&
                        markResp !== "accepted"
                          ? "#ed1010"
                          : "gray"
                      }`,
                    }}
                    className={`text-[] text-5xl cursor-pointer hover:text-[#ed1010]`}
                    onClick={() => {
                      sendRequest({
                        eventId: id,
                        data: {
                          reqBY: user?.id,
                          reqFOR: data?.cmtyUserld,
                          newStatus: "rejected",
                        },
                      });
                      setmarkResp("rejected");
                      dispatch(scanedUser(null));
                    }}
                  />
                  <CheckCircleFilled
                    className={`text-[${
                      (isRSData?.ResponseList?.find(
                        (d) => d.Matched._id === uid
                      )?.userResponse?.status === "accepted" ||
                        markResp === "accepted") &&
                      markResp !== "rejected"
                        ? "green"
                        : "gray"
                    }] text-5xl cursor-pointer hover:text-[green]`}
                    onClick={() => {
                      sendRequest({
                        eventId: id,
                        data: {
                          reqBY: user?.id,
                          reqFOR: data?.cmtyUserld,
                          newStatus: "accepted",
                        },
                      });
                      setmarkResp("accepted");
                      dispatch(scanedUser(null));
                    }}
                  />
                </div>
              </div>
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default ScanUser;

import Layout from "components/Layout";
import {
  useGetEventAttendeesQuery,
  useGetEventByIdQuery,
  useUpdateEventDurationMutation,
} from "features/events/eventSlice";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OrganiserAccess from "./OrganiserAccess";
import { useNavigate } from "react-router-dom";
import Details from "./OrganiserAccess/details";
import { StatusCheck } from "./utilFunction";
import EventStats from "./EventStats";
import { CLDropDown } from "components/DropDown";
import { CLModal } from "components/Modal";
import { CLInput } from "components/Input";
import { CLButton } from "components/Button";
import { InputNumber, notification } from "antd";

const Index = () => {
  const [eventDuration, seteventDuration] = useState(null);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const { data, error, isLoading, refetch } = useGetEventByIdQuery(id);
  const {
    data: attendeesData,
    error: attendeesError,
    isLoading: attendeesIsLoading,
  } = useGetEventAttendeesQuery({ id });

  const [updateEventDuration, { data: Response, error: isError, isSuccess }] =
    useUpdateEventDurationMutation();
  const bgStyle = {
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/Event/eventHero.svg"
    })`,
  };

  useEffect(() => {
    seteventDuration(data?.eventDuration);
  }, [data]);

  const updateDurationhandler = () => {
    updateEventDuration({ eventId: id, data: { duration: eventDuration } });
  };

  useEffect(() => {
    Response?.message &&
      notification.success({
        message: "Success",
        description: Response?.message,
      });
    setShow(false);
  }, [Response]);

  useEffect(() => {
    if (isSuccess) {
      refetch();
    }
  }, [isSuccess]);

  return (
    <Fragment>
      <header className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 items-center md:px-11 overscroll-none items-center">
        <div className="flex items-center">
          <div className="flex items-center">
            <img
              src="/images/Event/backBtn.svg"
              className="cursor-pointer mr-2"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="font-bold">Manage Event</div>
        </div>
        <section className="flex gap-2">
          {/* <article>
            <img
              src="/images/manageEvent/help.svg"
              className="cursor-pointer"
              alt="help"
            />
          </article>
          <article>
            {" "}
            <img
              src="/images/manageEvent/action.svg"
              className="cursor-pointer"
              alt="action"
            />
          </article> */}
          <article>
            <CLDropDown
              placement="bottomLeft"
              arrow
              menu={{
                items: [
                  {
                    label: "Update event duration",
                    key: "1",
                    onClick: () => {
                      setShow(true);
                    },
                  },
                ],
              }}
            >
              <img
                src="/images/manageEvent/settings.svg"
                className="cursor-pointer"
                alt="settings"
              />
            </CLDropDown>
          </article>
        </section>
      </header>
      <section className="p-10">
        {isLoading && "Loading..."}
        {error && "Error"}
        {data && !attendeesError && attendeesData && (
          <div className="flex flex-col md:flex-row gap-5">
            <section className="md:basis-full">
              {
                <div className="text-2xl font-semibold mb-2">
                  {data?.eventName}
                </div>
              }
              <div className="bg-cover bg-center mb-10 rounded-lg">
                <EventStats data={data} />
              </div>
              <div>
                <OrganiserAccess data={data} />
              </div>
            </section>
          </div>
        )}
        <div>
          {attendeesError && (
            <div>
              <div>
                This Page is not accessible, Only Event organizer can access
                this page. <br />
                But you can check event detail{" "}
                <span
                  className="text-[green] cursor-pointer font-bold"
                  onClick={() => {
                    navigate(`/event/${id}`);
                  }}
                >
                  here
                </span>
              </div>
            </div>
          )}
        </div>
      </section>
      <CLModal
        visible={show}
        footer={null}
        centered
        className="ffm"
        closable={false}
      >
        <section>
          <header className="text-md font-bold mb-3">
            Update Event Duration in Hours
          </header>
          <InputNumber
            className="rounded h-12 border-2 p-2 border-primary-main w-full"
            placeholder="No. of Hours"
            defaultValue={eventDuration}
            onChange={(value) => {
              seteventDuration(value);
            }}
          />
          <section className="flex gap-2 justify-end mt-4">
            <CLButton
              onClick={() => setShow(false)}
              className="text-primary-main rounded-full  h-10 flex items-center justify-center mb-2 w-[88px]"
            >
              Cancel
            </CLButton>
            <CLButton
              onClick={() => updateDurationhandler()}
              className="bg-primary-main text-white rounded-full w-[88px] hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2"
            >
              Update
            </CLButton>
          </section>
        </section>
      </CLModal>
    </Fragment>
  );
};

export default Index;

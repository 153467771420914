import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { CLButton } from "components/Button";
import Attendeeflow from "./attendeeflow";
import Organiserflow from "./organiserflow";
import { TagCloud } from "react-tagcloud";
const data = [
  { value: "Connections", count: 25 },
  { value: "Events", count: 18 },
  { value: "Flexible", count: 38 },
  { value: "Tools", count: 30 },
  { value: "Social", count: 28 },
  { value: "Dance", count: 25 },
  { value: "Music", count: 33 },
  { value: "singing", count: 20 },
  { value: "Offline-support", count: 22 },
];

const HomeEvents = () => {
  const [MainContainer, setMainContainer] = useState("user");
  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
  }, [MainContainer]);
  return (
    <Layout className="ffm">
      <section className="p-10">
        <section className="mb-[100px] p-5 flex justify-center">
          <div>
            {" "}
            <article className="flex gap-5 flex-col md:flex-row landingModal">
              <section className="b-1 md:w-[300px]">
                <div className="mb-2 flex items-center gap-3 text-lg">
                  One place to connect and chat with all event attendees.
                </div>
                <div className="mb-2 flex items-center gap-3 text-lg">
                  Build connections how you want, maintain privacy & control.
                </div>
                <div className="mb-2 flex items-center gap-3 text-lg">
                  Connect with organisers and even run your own events.
                </div>
              </section>
              <section className="md:w-[200px]">
                <TagCloud
                  minSize={12}
                  maxSize={35}
                  tags={data}
                  className="simple-cloud"
                />
              </section>
            </article>
            <article className="mt-[100px] flex gap-5 justify-between md:pl-[100px] md:pr-[100px]">
              <CLButton
                className={
                  MainContainer === "user"
                    ? "bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center w-[150px]"
                    : "bg-white  text-primary-light rounded-full h-10 flex items-center justify-center mb-2 w-[150px]"
                }
                onClick={() => {
                  setMainContainer("user");
                }}
              >
                Attending Event ?
              </CLButton>
              <CLButton
                className={
                  MainContainer === "organiser"
                    ? "bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[150px]"
                    : "bg-white  text-primary-light rounded-full h-10 flex items-center justify-center mb-2 w-[150px]"
                }
                onClick={() => {
                  setMainContainer("organiser");
                }}
              >
                Organiser
              </CLButton>
            </article>
            <article className="mt-5 landingModal">
              {MainContainer === "user" ? <Attendeeflow /> : <Organiserflow />}
            </article>
          </div>
        </section>
      </section>
    </Layout>
  );
};
export default HomeEvents;

import React, { useEffect } from "react";
import Layout from "components/Layout";
import { useDispatch, useSelector } from "react-redux";
import { CLAvatar } from "components/Avatar";
import { loadUser } from "features/auth/authActions";

const Index = () => {
  const dispatch = useDispatch();
  const { user, Access } = useSelector((state) => state.auth);
  useEffect(() => {
    dispatch(loadUser());
  }, []);
  return (
    <Layout className="ffm">
      <section className="p-10">
        <section className="mb-2">
          <CLAvatar size={64} src={"/images/avatarDummy.svg"} />
        </section>
        <section className="mb-2">
          <span className="font-bold mr-2">Name:</span>
          {user?.full_name}
        </section>
        <section className="mb-2">
          <span className="font-bold mr-2"> Plan type:</span>
          {user?.plan_type}
        </section>
        <section className="mb-2">
          <span className="font-bold mr-2"> Display name:</span>
          {user?.display_name}
        </section>
        <section className="mb-2">
          <span className="font-bold mr-2"> Email:</span>
          {user?.email}
        </section>
        <section className="mb-2">
          <span className="font-bold mr-2"> About me:</span>
          {user?.about_me}
        </section>
      </section>
    </Layout>
  );
};

export default Index;

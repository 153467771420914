import React, { Fragment, useEffect } from "react";
import Layout from "components/Layout";
import EventCard from "components/EventCard";
import { useGetAllEventsQuery } from "features/events/eventSlice";
import { CLInput } from "components/Input";
import { SearchOutlined } from "@ant-design/icons";

const AllEvents = () => {
  const { data, error, isLoading, refetch } = useGetAllEventsQuery();
  const onSearch = (value) => {
    console.log(value);
  };
  useEffect(() => {
    refetch();
  }, []);
  return (
    <Layout className="ffm">
      <section className="p-10">
        {isLoading && "Loading..."}
        {error && <div className="mb-5">{error?.data?.message}</div>}
        {data && (
          <section className="text-4xl font-semibold text-center mb-10">
            All Events
          </section>
        )}
        {data && data?.length > 0 && (
          <Fragment>
            <section className="mb-10 flex gap-3">
              <CLInput
                className="rounded h-12 border-2 p-2 w-[280px] border-primary-main"
                prefix={<SearchOutlined />}
                placeholder="Search event by name"
              />
            </section>
          </Fragment>
        )}
        {data && data?.length === 0 && (
          <div className="text-xl font-semibold text-center mb-10">
            No events found, You can either create or join one by using
            Juznow.com
          </div>
        )}
        <section className="flex gap-5 justify-center flex-wrap lg:justify-start min-h-[500px]">
          {data &&
            data.map((event, i) => <EventCard event={event} footer={false} />)}
        </section>
      </section>
    </Layout>
  );
};
export default AllEvents;

import { CLButton } from "components/Button";
import { CLImage } from "components/Image";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCookie, setCookie, truncateSt } from "utils/helper";
import { CLModal } from "components/Modal";
import { Spin } from "antd";
import { useAddChatMutation } from "features/user/userSlice";
import { useGetEventByIdQuery } from "features/events/eventSlice";
import { useGetUserEmailQuery } from "features/user/userSlice";
import { useNavigate, useParams } from "react-router-dom";
import { MoreOutlined } from "@ant-design/icons";
import { showEmailOverChatInResponse } from "config/UX";
import { CLPopover } from "components/PopOver";
import { Radio } from "antd";
import { useSendMatchRequestMutation } from "features/connection/connectionSlice";
import { CLNotification } from "components/Notification";
import { Dropdown, message, Space } from "antd";
const Index = ({ data, Uname, refetchD }) => {
  const Navigate = useNavigate();
  const [SelectResponse, setSelectResponse] = useState(
    data?.userResponse?.status
  );
  const [Redirect, setRedirect] = useState(false);
  const [changeResposne, setChangeResponse] = useState(false);
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const [
    addChat,
    { isLoading: isAddingChat, isSuccess: isChatSuccess, error: isChatError },
  ] = useAddChatMutation();
  const {
    data: matchData,
    loading: matchLoading,
    refetch,
  } = useGetEventByIdQuery(id);
  const [
    sendRequest,
    { isLoading: isSending, isSuccess: isSent, error: isError, data: isData },
  ] = useSendMatchRequestMutation();
  const Chat = async () => {
    setRedirect(true);
    addChat({
      addUser: {
        aUid: data.Matched._id,
        name: data.Matched.name,
        pic: data?.Matched?.profile_image,
        status: data?.Matched?.status,
      },
      byUser: { aUid: user.id, name: Uname, pic: user?.profile_image },
      event: {
        ...matchData,
      },
    });
  };
  const openChat = () => {
    Navigate("/chat", { state: { matchedId: data.Matched._id } });
  };
  useEffect(() => {
    if (isChatSuccess && Redirect) {
      // let token = getCookie("cl_a");
      // setCookie("jn_c", token, 12 * 60 * 60 * 24);
      openChat();
      setRedirect(false);
    }
  }, [isChatSuccess]);
  useEffect(() => {
    if (isChatError) {
      setRedirect(false);
      CLNotification({
        type: "error",
        description: isChatError?.data?.message ?? isChatError?.message,
      });
    }
  }, [isChatError]);
  const CheckResponse = (resp) => {
    if (resp === "accepted") {
      return (
        <span className="text-green-act p-1 border rounded-lg text-[10px]">
          Yes
        </span>
      );
    }
    if (resp === "rejected") {
      return (
        <span className="text-red-act p-1 border rounded-lg text-sm">No</span>
      );
    }
    if (resp === "pending") {
      return (
        <span className="text-orange p-1 border rounded-lg text-sm">
          Pending
        </span>
      );
    }
  };
  const setUpdateResponse = () => {
    sendRequest({
      eventId: id,
      data: {
        reqBY: user?.id,
        reqFOR: data?.Matched?._id,
        newStatus: SelectResponse,
      },
    });
    setChangeResponse(false);
  };
  useEffect(() => {
    if (isError) {
      CLNotification({
        type: "error",
        description: isError?.data?.message ?? isData?.message,
      });
    }
    if (isSent || isData) {
      CLNotification({
        type: "success",
        description: "Response updated successfully",
      });
      refetch();
      refetchD();
    }
  }, [isSent, isError, isData]);
  const items = [
    {
      label: "Change Response",
      key: "1",
      onClick: () => {
        setChangeResponse(true);
      },
    },
  ];
  return (
    <div className="flex border-2 w-[343px] h-[148px] p-1 rounded-md border-[#ebebeb] cursor-pointer relative">
      {!matchData?.Tags?.includes("social") && (
        <div className="absolute right-0">
          <Dropdown
            placement="bottomLeft"
            menu={{
              items,
            }}
          >
            <MoreOutlined />
          </Dropdown>
        </div>
      )}

      <div className="flex items-center">
        <CLImage
          width={116}
          height={116}
          src={data?.Matched?.profile_image ?? `/images/avatarDummy.svg`}
        />
      </div>

      <div className="ml-2">
        <div className="mb-2 font-semibold">
          {truncateSt(
            data?.Matched?.alias?.length > 0
              ? data?.Matched?.alias
              : data?.Matched?.name,
            20
          )}
        </div>
        {!matchData?.Tags?.includes("social") && (
          <div className="text-[12px] mb-4">
            Your Response: {CheckResponse(data?.userResponse?.status)}
          </div>
        )}
        {!showEmailOverChatInResponse && (
          <CLButton
            style={{
              marginTop: matchData?.Tags?.includes("social") && 20,
            }}
            disabled={
              data.Matched.status === "accepted" &&
              data.userResponse.status === "accepted"
                ? false
                : true
            }
            onClick={() => Chat()}
            className="border-primary-main text-primary-main rounded-full w-full h-8 flex items-center justify-center font-semibold mb-1"
          >
            <img src="/images/chatMatch.svg" alt="chat" className="w-4 mr-2" />
            CHAT
          </CLButton>
        )}
      </div>

      <CLModal
        open={Redirect}
        footer={null}
        centered
        closable={false}
        // onCancel={() => setRedirect(false)}
      >
        <div className="flex justify-center">
          <Spin tip="Please wait while we redirect you to chat." size="small" />
        </div>
      </CLModal>
      <CLModal open={changeResposne} footer={null} centered closable={false}>
        <section>
          <header className="mb-2 text-xl">Change Response</header>
          <div className="flex items-center bg-[#f5f5f5] p-2 gap-2 text-[black]">
            <CLImage
              width={64}
              height={64}
              src={data?.Matched?.profile_image ?? `/images/avatarDummy.svg`}
            />
            {truncateSt(
              data?.Matched?.alias?.length > 0
                ? data?.Matched?.alias
                : data?.Matched?.name,
              20
            )}
          </div>
        </section>
        <section className="mt-3">
          <div>Change the response to</div>
          <div className="mt-2">
            <Radio.Group
              onChange={(e) => setSelectResponse(e.target.value)}
              defaultValue={data?.userResponse?.status}
            >
              <section className="flex gap-3 ">
                <div className="border p-2 rounded-xl border-[#e0e0e0]">
                  <Radio value={"rejected"}>Reject</Radio>
                </div>
                <div className="border p-2 rounded-xl border-[#e0e0e0]">
                  <Radio value={"accepted"}>Accept</Radio>
                </div>
              </section>
            </Radio.Group>
          </div>
        </section>
        <section className="flex justify-end gap-2 mt-4">
          <CLButton
            className="rounded-full w-full h-8 flex items-center justify-center font-semibold mb-1"
            onClick={() => setChangeResponse(false)}
          >
            Cancel
          </CLButton>
          <CLButton
            onClick={() => setUpdateResponse()}
            className="border-primary-main text-primary-main rounded-full w-full h-8 flex items-center justify-center font-semibold mb-1"
          >
            Update
          </CLButton>
        </section>
      </CLModal>
    </div>
  );
};

export default Index;

import React from "react";
import { Form } from "antd";

export const CLForm = (props) => {
  return <Form {...props} />;
};

export const CLFormItem = (props) => {
  return <Form.Item {...props} />;
};

import React, { useEffect } from "react";
import AllEvents from "./HomeEvents";
import Layout from "components/Layout";
import Footer from "components/Footer";

const HomePage = () => {
  return (
    <Layout>
      <AllEvents />
      <div style={{ position: "fixed", bottom: 0, width: "100%" }}>
        <Footer />
      </div>
    </Layout>
  );
};

export default HomePage;

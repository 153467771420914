import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "utils/helper";
import { authUrl } from "config/url";

export const connectionApi = createApi({
  reducerPath: "connectionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: authUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = getCookie("cl_a");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    sendMatchRequest: builder.mutation({
      query: (body) => {
        const { data, eventId } = body;
        return {
          url: `event/express-interest/${eventId}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getMatchesByEvent: builder.query({
      query: (body) => {
        const { eventId, userId } = body;
        return {
          url: `event/${eventId}/matches/${userId}`,
          method: "GET",
        };
      },
    }),
    sendQuickMessage: builder.mutation({
      query: (body) => ({
        url: `chat/update`,
        method: "POST",
        body,
      }),
    }),
    checkMatchStatus: builder.query({
      query: (body) => {
        const { eventId, userId } = body;
        return {
          url: `event/${eventId}/matches/${userId}`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useSendMatchRequestMutation,
  useGetMatchesByEventQuery,
  useSendQuickMessageMutation,
} = connectionApi;

import React, { Fragment, useEffect, useState } from "react";
import { CLButton } from "components/Button";
import { CLQRCode } from "components/QRCode";
import QrReader from "modern-react-qr-reader";
import {
  QrcodeOutlined,
  AppstoreAddOutlined,
  FileSearchOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CLNotification } from "components/Notification";
import { CLModal } from "components/Modal";
import ModalAliasSearch from "./OrganiserAccess/ModalAliasSearch";
import { scanedUser } from "features/auth/authSlice";
const SpeedDatingAction = ({ dataSet, id }) => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [qrscan, setQrscan] = useState(null);

  useEffect(() => {
    if (qrscan) {
      Navigate(`/event/${id}/event-dashboard/scanned-user/${qrscan}`);
      dispatch(scanedUser(qrscan));
    }
  }, [qrscan]);
  return (
    <Fragment>
      <section className="mt-3">
        {/* <section className="">
          <CLButton className="bg-primary-main text-white rounded-full w-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2">
            <FileSearchOutlined />
            Search Alias
          </CLButton>
        </section> */}
        <div className="mt-4">
          <ModalAliasSearch id={id} setQrscan={setQrscan} />
        </div>
      </section>
    </Fragment>
  );
};

export default SpeedDatingAction;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "utils/helper";
import { authUrl } from "config/url";

// get my reservations'

export const reservationApi = createApi({
  reducerPath: "reservationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: authUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getCookie("cl_a");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getMyReservations: builder.query({
      query: () => ({
        url: `reservation/upcoming-events`,
        method: "GET",
      }),
    }),
    getEventReservations: builder.query({
      query: (eventId) => ({
        url: `reservation/event/${eventId}`,
        method: "GET",
      }),
    }),
    createReservation: builder.mutation({
      query: (body) => {
        const { eventId, data } = body;
        return {
          url: `reservation/event/${eventId}`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateReservation: builder.mutation({
      query: (body) => {
        const { reservationId, data } = body;
        return {
          url: `reservations/${reservationId}`,
          method: "PATCH",
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetMyReservationsQuery,
  useGetEventReservationsQuery,
  useCreateReservationMutation,
  useUpdateReservationMutation,
} = reservationApi;

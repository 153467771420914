import axios from "axios";
import { logoutAction } from "features/auth/authSlice";
import { store } from "../app/store";
import { getCookie } from "./helper";
import { authUrl } from "config/url";

const api = axios.create({
  baseURL: authUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getCookie("cl_a")}`,
  },
});

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      store.dispatch(logoutAction());
    }
    return Promise.reject(err);
  }
);

export default api;

import moment from "moment";
import React from "react";
import { endDate } from "./utilFunction";

const EventStats = ({ data, showName = false }) => {
  return (
    <section className="p-2 mb-4 py-5 rounded-md dt-card border-[#ebebeb]">
      {showName && (
        <div className="text-lg font-bold mb-2">{data?.eventName}</div>
      )}
      <article className="mb-2">
        <div className="flex items-center">
          <img src="/images/Event/date.svg" alt="location" className="mr-2" />
          <div className="ml-1 text-sm">
            {moment(data?.eventStartDateAndTime).format("lll")}
          </div>
          <span className="ml-2">to</span>
          <div className="ml-2 text-sm">
            {endDate(data?.eventStartDateAndTime, data?.eventDuration)}
          </div>
        </div>
      </article>
      <article>
        <div className="flex items-center">
          <img
            src="/images/Event/locationIcon.svg"
            alt="location"
            className="mr-2"
          />
          <div className="pl-1 text-sm flex">{data?.location}</div>
        </div>
      </article>
    </section>
  );
};

export default EventStats;

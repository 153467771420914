import { CLButton } from "components/Button";
import React, { useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { CLInput } from "components/Input";
import { useGetEventAttendeesQuery } from "features/events/eventSlice";
import { useParams } from "react-router-dom";
import { useSendMatchRequestMutation } from "features/connection/connectionSlice";
import { CLNotification } from "components/Notification";
import { useSelector } from "react-redux";
import { CLAvatar } from "components/Avatar";

const UserResponseModal = ({
  closeResponseModal,
  responseList,
  userName,
  refetchAll,
}) => {
  const { id, uid } = useParams();
  const { auth } = useSelector((state) => state);
  const [searchString, setSearchString] = useState("");
  const [
    sendRequest,
    { isLoading: isSending, isSuccess: isSent, error: isError, data: isData },
  ] = useSendMatchRequestMutation();
  const colorPalette = {
    pending: "orange",
    accepted: "green",
    rejected: "red",
  };
  const { data, error, isLoading, refetch } = useGetEventAttendeesQuery(
    {
      id,
      search: searchString,
    },
    {
      enabled: () => false,
    }
  );
  const findAttendee = (e) => {
    const searchValue = e.target.value;
    setSearchString(searchValue);
    if (!searchValue) {
      refetch();
    }
  };
  useEffect(() => {
    if (searchString.length > 0) {
      refetch();
    }
  }, [searchString]);
  const checkUserResponse = (res) => {
    let user = responseList?.ResponseList?.find(
      (user) => user.Matched?._id === res.reservedBy.cmtyUserld
    );
    if (user) {
      if (
        (user?.userResponse?.hasUpdatedResponse === "User" ||
          user?.userResponse?.hasUpdatedResponse === "SiteAdmin") &&
        auth?.user?.id !== uid
      ) {
        return (
          <div className="flex gap-5">
            <span
              style={{
                color: colorPalette[user?.userResponse?.status],
              }}
            >
              {`${user?.userResponse?.status.toUpperCase()}`} (by user)
            </span>
          </div>
        );
      } else {
        return (
          <div className="flex gap-5">
            <CLButton
              style={{
                border:
                  user?.userResponse?.status === "accepted" &&
                  `1px solid ${colorPalette["accepted"]}`,
                color:
                  user?.userResponse?.status === "accepted" &&
                  `${colorPalette["accepted"]}`,
              }}
              className="text-xs rounded-full"
              onClick={() => {
                sendRequest({
                  eventId: id,
                  data: {
                    reqBY: uid,
                    reqFOR: res?.reservedBy?.cmtyUserld,
                    newStatus: "accepted",
                  },
                });
              }}
            >
              {user?.userResponse?.status === "accepted"
                ? "Accepted"
                : `Accept`}
            </CLButton>
            <CLButton
              className="text-xs rounded-full"
              style={{
                border:
                  user?.userResponse?.status === "rejected" &&
                  `1px solid ${colorPalette["rejected"]}`,
                color:
                  user?.userResponse?.status === "rejected" &&
                  `${colorPalette["rejected"]}`,
              }}
              onClick={() => {
                sendRequest({
                  eventId: id,
                  data: {
                    reqBY: uid,
                    reqFOR: res?.reservedBy?.cmtyUserld,
                    newStatus: "rejected",
                  },
                });
              }}
            >
              {user?.userResponse?.status === "rejected"
                ? "Rejected"
                : `Reject`}
            </CLButton>
          </div>
        );
      }
    } else {
      return (
        <div className="flex gap-5">
          <CLButton
            className="text-xs rounded-full"
            onClick={() => {
              sendRequest({
                eventId: id,
                data: {
                  reqBY: uid,
                  reqFOR: res?.reservedBy?.cmtyUserld,
                  newStatus: "accepted",
                },
              });
            }}
          >
            Accept
          </CLButton>
          <CLButton
            className="text-xs rounded-full"
            onClick={() => {
              sendRequest({
                eventId: id,
                data: {
                  reqBY: uid,
                  reqFOR: res?.reservedBy?.cmtyUserld,
                  newStatus: "rejected",
                },
              });
            }}
          >
            Reject
          </CLButton>
        </div>
      );
    }
  };
  useEffect(() => {
    if (isSent || isError) {
      refetchAll();
      isSent &&
        CLNotification({
          type: "success",
          description: isError?.data?.message ?? isData?.message,
        });
      isError &&
        CLNotification({
          type: "error",
          description: isError?.data?.message ?? isData?.message,
        });
    }
  }, [isSent, isError]);
  return (
    <div className="h-[500px]">
      <header className="flex justify-between items-center">
        <h1>
          Responses on behalf of <span className="font-bold ">{userName}</span>
        </h1>
        <CLButton onClick={() => closeResponseModal()}>X</CLButton>
      </header>
      <section>
        <div>
          <CLInput
            className="w-100 h-[40px] mt-2"
            placeholder="Search attendee using alias or name"
            onChange={(e) => findAttendee(e)}
          />
        </div>
      </section>
      <section className="mt-3 overflow-scroll h-[400px]">
        {data?.map((res) => {
          if (res.reservedBy.cmtyUserld !== uid) {
            return (
              <section className="mb-6 border-2 p-2 border-[#ebebeb]">
                <section className="flex justify-between bg-[#f5f5f5] p-3">
                  <article className="flex justify-between gap-2">
                    <div>
                      <CLAvatar size={48} src={"/images/avatarDummy.svg"} />
                    </div>
                    <div>
                      <div className="font-bold">{res?.reservedBy?.name}</div>
                      <div className="text-[gray]">
                        Alias: {res?.alias ?? "N/A"}
                      </div>
                    </div>
                  </article>
                </section>
                <section className="flex gap-5 mt-2 items-center">
                  <span className="font-bold mr-2">Response:</span>
                  {checkUserResponse(res)}
                </section>
              </section>
            );
          }
        })}
      </section>
    </div>
  );
};

export default UserResponseModal;

import React, { useEffect } from "react";
import { CustomRouter } from "Routes/CustomRouter";
import history from "Routes/history";
import { RouteConfig } from "Routes";
import "./App.css";
import { useDispatch } from "react-redux";
import { logoutAction, setAccess } from "features/auth/authSlice";
import { getCookie } from "utils/helper";
import { loadUser } from "features/auth/authActions";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    let cookie = getCookie("cl_a");
    cookie && dispatch(loadUser());
    let Organizer = getCookie("cl_o");
      if (!Organizer) {
        dispatch(setAccess("USER"));
      } else {
        dispatch(setAccess("ORGANIZER"));
      }
    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      let cookie = getCookie("cl_a");
      if (!cookie) {
        dispatch(logoutAction());
      }
    
    });
  }, []);
  return (
    <CustomRouter history={history}>
      <RouteConfig />
    </CustomRouter>
  );
}

export default App;

import React from "react";
import moment from "moment";
import { truncateSt } from "utils/helper";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Fragment } from "react";
import { useSelector } from "react-redux";

const OrganiserEventCard = ({ event }) => {
  const { Access } = useSelector((state) => state.auth);
  const Navigate = useNavigate();
  const bgStyle = {
    backgroundImage: `url(${
      event?.mediaList.length > 0
        ? event?.mediaList[0]
        : process.env.PUBLIC_URL + "/images/Event/eventCardHero.svg"
    })`,
    backgroundRepeat: "no-repeat",
  };
  const StatusCheck = (date, duration) => {
    const curruntDate = moment();
    const eventDate = moment(date);
    const eventEndDate = moment(date).add(duration, "hours");
    if (curruntDate.isAfter(eventDate) && curruntDate.isBefore(eventEndDate)) {
      return "Attending";
    } else if (curruntDate.isAfter(eventDate)) {
      return "Attended";
    } else {
      return "Upcoming";
    }
  };
  return (
    <Fragment>
      <section
        className="flex w-full h-[200px] md:h-[220px] bg-white md:rounded"
        onClick={() => Navigate(`/organiser/${event?._id}`)}
      >
        <div>
          <article
            className="h-[100px] md:h-[220px] w-[120px] md:w-[350px] bg-cover bg-center md:rounded"
            style={bgStyle}
          ></article>
          <div className="border-r-2 mt-2 md:mt-3 p-2 ml-[5px] border-[#e0e0e0] md:hidden">
            <section className="text-sm">
              <div>Attendee : {event?.totalAttendees ?? 0}</div>
            </section>
          </div>
        </div>

        <article className="p-2 md:p-5 w-full">
          <div>
            <div className="text-lg sm:text-2xl font-semibold h-[62px]">
              {truncateSt(event?.eventName, 20)}
            </div>
          </div>
          <div>
            <section className="bg-[#F5F5F5] md:p-5 p-2 rounded-lg text-[12px] md:text-[14px] w-full">
              <div className="mb-2">
                <div className="flex items-center">
                  <img
                    src="/images/Event/date.svg"
                    alt="location"
                    className="mr-2"
                  />{" "}
                  <div className="ml-1">
                    {moment(event?.eventStartDateAndTime).format(
                      "MMM Do YYYY, HH:mm a"
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="flex items-center">
                  <img
                    src="/images/Event/locationIcon.svg"
                    alt="location"
                    className="mr-2"
                  />
                  <div className="ml-1"> {event?.location}</div>
                </div>
              </div>
            </section>
            <div className="flex text-[11px] gap-1 mt-2 md:text-[14px]">
              {Access === "USER" && (
                <section className="">
                  <section className="bg-orange text-white flex justify-center font-medium rounded px-1 py-1 md:px-2">
                    {StatusCheck(
                      event?.eventStartDateAndTime,
                      event?.eventDuration
                    )}
                  </section>
                </section>
              )}
              <section className="">
                {event?.Tags.includes("speeddating") && (
                  <section className="bg-tags text-white  flex justify-center font-medium rounded px-1 py-1 md:px-2">
                    Speed Dating
                  </section>
                )}
                {event?.Tags.includes("social") && (
                  <section className="bg-tags text-white  flex justify-center font-medium rounded px-1 py-1 md:px-2">
                    Social Event
                  </section>
                )}
              </section>
            </div>
          </div>
        </article>
        <div className="border-l-2 mr-5 my-10 p-2 w-[200px] md:block hidden border-[#e0e0e0]">
          <section className="flex flex-col">
            <div>Attendee : {event?.totalAttendees ?? 0}</div>
          </section>
        </div>
        <article className="orgStrip md:w-[23px] w-[5px] text-[10px] md:text-[16px] md:hover:w-[23px] transition-width duration-300">
          <div className="opacity-0 md:opacity-0 hover:opacity-100">
            Organiser
          </div>
        </article>
      </section>
    </Fragment>
  );
};

export default OrganiserEventCard;

import moment from "moment";

export const StatusCheck = (date, duration) => {
  const curruntDate = moment();
  const eventDate = moment(date);
  const eventEndDate = moment(date).add(duration, "hours");
  if (curruntDate.isAfter(eventDate) && curruntDate.isBefore(eventEndDate)) {
    return "Running";
  } else if (curruntDate.isAfter(eventDate)) {
    return "Finished";
  } else {
    return "Not Started Yet";
  }
};

export const endDate = (date, duration) => {
  const eventEndDate = moment(date).add(duration, "hours");
  if (moment(date).isSame(eventEndDate, "day")) {
    return moment(eventEndDate).format("hh:mm A");
  } else {
    return moment(eventEndDate).format("lll");
  }
};

import { CLInput } from "components/Input";
import { useGetUserByAliasQuery } from "features/events/eventSlice";
import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { CLButton } from "components/Button";

const ModalAliasSearch = ({ id, setmanualSearch, setQrscan }) => {
  const [val, setval] = useState("");
  const [skip, setskip] = useState(true);
  const { data, error, isLoading, refetch } = useGetUserByAliasQuery(
    {
      id,
      alias: val,
    },
    { skip }
  );

  useEffect(() => {
    val.length > 0 && setskip(false);
  }, [val]);
  return (
    <div className="h-[300px]">
      <section>
        <CLInput
          className="rounded h-12 border-2 p-2 border-primary-main"
          prefix={<SearchOutlined />}
          placeholder="Search User by Alias"
          onChange={(e) => setval(e.target.value)}
          value={val}
        />
      </section>
      {!isLoading && (error || data) && (
        <section className="h-[220px] mt-2 overflow-scroll">
          {!isLoading && data?.length === 0 && val.length > 0 && (
            <div className="text-center font-semibold">No user found</div>
          )}

          {!isLoading && !error && data?.length > 0 && (
            <div className="flex flex-col">
              <div className="text-center mb-2 font-semibold">Connect With</div>
              {data?.map((item) => {
                return (
                  <CLButton
                    className="bg-primary-main hover:!text-white text-white font-medium  hover:bg-primary-light h-10 mb-2"
                    onClick={() => {
                      setQrscan(item._id);
                      setmanualSearch(false);
                      setval("");
                    }}
                  >
                    {item.name}
                  </CLButton>
                );
              })}
            </div>
          )}
          {!isLoading && error && (
            <div className="flex justify-center">{error?.data?.message}</div>
          )}
        </section>
      )}
    </div>
  );
};

export default ModalAliasSearch;

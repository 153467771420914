import { CLAvatar } from "components/Avatar";
import { CLOption, CLSelect } from "components/Select";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { useSendMatchRequestMutation } from "features/connection/connectionSlice";
import { CLNotification } from "components/Notification";

const ResponseCard = ({ attendee, user, refetch }) => {
  const { id, uid } = useParams();
  const [Show, setShow] = useState(false);
  const { auth } = useSelector((state) => state);
  const colorPalette = {
    pending: "orange",
    accepted: "green",
    rejected: "red",
  };
  const ChangeResponse = (value, reqFOR) => {
    sendRequest({
      eventId: id,
      data: {
        reqBY: uid,
        reqFOR,
        newStatus: value,
      },
    });
  };
  const [
    sendRequest,
    { isLoading: isSending, isSuccess: isSent, error: isError, data: isData },
  ] = useSendMatchRequestMutation();
  useEffect(() => {
    if (isSent || isError) {
      refetch();
      isSent &&
        CLNotification({
          type: "success",
          description: isError?.data?.message ?? isData?.message,
        });
      isError &&
        CLNotification({
          type: "error",
          description: isError?.data?.message ?? isData?.message,
        });
    }
  }, [isSent, isError]);
  return (
    <div className="bg-white p-4 m-2 h-[140px] text-[10px] md:text-[16px]">
      <section className="flex justify-between bg-[#f5f5f5] p-3">
        <article className="flex justify-between gap-2">
          <div>
            <CLAvatar size={48} src={"/images/avatarDummy.svg"} />
          </div>
          <div>
            <div className="font-bold">{attendee?.Matched?.name}</div>
            <div className="text-[gray]">Alias: {attendee?.Matched?.alias ?? "N/A"}</div>
          </div>
        </article>
        <article>
          <div
            onClick={() => setShow(!Show)}
            className="cursor-pointer block md:hidden text-[re]"
          >
            {!Show && `View`} {attendee?.Matched?.name.split(" ")[0]}'s <br />
            Response{" "}
          </div>
          <div
            onClick={() => setShow(!Show)}
            className="cursor-pointer md:block hidden"
          >
            {!Show && `View`} {attendee?.Matched?.name.split(" ")[0]}'s Response{" "}
          </div>
          {Show && (
            <div
              className="flex justify-end font-bold"
              style={{
                color: colorPalette[attendee?.Matched?.status],
              }}
            >
              {attendee?.Matched?.status.charAt(0).toUpperCase() +
                attendee?.Matched?.status.slice(1)}
            </div>
          )}
        </article>
      </section>
      <section className="mt-2">
        {user.split(" ")[0]}'s Response:
        {attendee?.userResponse?.hasUpdatedResponse !== "EventAdmin" &&
          auth?.user?.id !== uid && (
            <span
              className="ml-2 font-bold"
              style={{
                color: colorPalette[attendee?.userResponse?.status],
              }}
            >
              {attendee?.userResponse?.status} (by user)
            </span>
          )}
        {(attendee?.userResponse?.hasUpdatedResponse === "EventAdmin" ||
          auth?.user?.id === uid) && (
          <span
            className="ml-2 font-bold"
            style={{
              color: colorPalette[attendee?.userResponse?.status],
            }}
          >
            {/* {attendee?.userResponse?.status} */}
            {!isSending && (
              <CLSelect
                key={attendee?.userResponse?.status}
                className="w-[127px] md:w-[300px]"
                defaultValue={attendee?.userResponse?.status}
                onChange={(value) =>
                  ChangeResponse(value, attendee?.Matched?._id)
                }
              >
                <CLOption value="accepted">Accepted</CLOption>
                <CLOption value="rejected">Rejected</CLOption>
              </CLSelect>
            )}
            {isSending && <LoadingOutlined />}
          </span>
        )}
      </section>
    </div>
  );
};

export default ResponseCard;

import Layout from "components/Layout";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useGetMyReservationsQuery } from "features/reservations/reservationSlice";
import moment from "moment";
import { CLButton } from "components/Button";
import { CLDivider } from "components/Divider";
import { CheckEventStatus, getCookie } from "utils/helper";
import { useSelector } from "react-redux";
import { useGetOrgUpcommingEventsQuery } from "features/events/eventSlice";
import UserDash from "./UserDash";
import OrgDash from "./OrgDash";
import ToolBar from "components/EventToolbar";

const Index = () => {
  const navigate = useNavigate();
  const { Access } = useSelector((state) => state.auth);
  const bgStyle = {
    backgroundImage:
      Access === "USER" &&
      `url(${process.env.PUBLIC_URL + "/images/Event/eventHero.svg"})`,
    backgroundColor: Access === "ORGANIZER" && "#1D1D1D",
  };
  const { data, error, isLoading, refetch } = useGetMyReservationsQuery();
  const {
    data: OrgData,
    error: OrgError,
    isLoading: OrgLoading,
    refetch: OrgRefetch,
  } = useGetOrgUpcommingEventsQuery();

  useEffect(() => {
    refetch();
    OrgRefetch();
  }, []);

  const EventStats = (data) => {
    let running = [];
    let upcoming = [];
    let past = [];
    data?.map((item) => {
      if (moment().isBefore(moment(item?.event?.eventStartDateAndTime))) {
        upcoming.push(item);
      } else if (
        moment().isAfter(
          moment(item?.event?.eventStartDateAndTime).add(
            item?.event?.eventDuration,
            "hours"
          )
        )
      ) {
        past.push(item);
      } else {
        running.push(item);
      }
    });
    if (running.length > 0) {
      return running;
    } else {
      return upcoming;
    }
  };
  const calculateRunTime = (data) => {
    let eventStartDateAndTime = moment(data?.[0]?.event?.eventStartDateAndTime);
    const eventDurationInHours = data?.event?.eventDuration;
    const eventEndDate = eventStartDateAndTime
      .clone()
      .add(eventDurationInHours, "hours");
    const isEventInPast = moment().isAfter(eventEndDate);
    if (isEventInPast) {
      return true;
    } else {
      return false;
    }
  };
  const calculateRunTimeU = (data) => {
    let eventStartDateAndTime = moment(data?.event?.eventStartDateAndTime);
    const eventDurationInHours = data?.event?.eventDuration;
    const eventEndDate = eventStartDateAndTime
      .clone()
      .add(eventDurationInHours, "hours");
    const isEventInPast = moment().isAfter(eventEndDate);
    if (isEventInPast) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Layout>
      <section className="p-10">
        <section plain className="text-4xl font-semibold text-center mb-10">
          {Access === "USER" ? "My Events" : "Organiser Dashboard"}
        </section>
        <div
          className="shadow-md md:h-[300px] h-[320px] bg-cover bg-center mb-10 rounded-lg"
          style={bgStyle}
        >
          {(Access === "USER" && data?.length < 1) ||
          (Access === "USER" &&
            calculateRunTimeU(
              data?.map((res) => {
                ["Ongoing", "Upcoming"].includes(
                  CheckEventStatus(
                    res?.event?.eventStartDateAndTime,
                    res?.event?.eventDuration
                  ) && res
                );
              })[0]
            )) ||
          (Access === "ORGANIZER" && OrgData?.length < 1) ||
          (Access === "ORGANIZER" && calculateRunTime(data?.[0])) ? (
            <section className="text-white p-4 md:pt-10 md:pl-10 text-3xl font-medium">
              {Access === "ORGANIZER"
                ? `You have no upcoming events, Create one with juznow.com`
                : ``}
              {Access === "USER" ? `You have no upcoming events` : ``}
            </section>
          ) : (
            <section className="text-white p-4 md:pt-5 md:pl-5">
              {Access === "ORGANIZER" && (
                <article className="text-center text-3xl font-extrabold mb-2">
                  {EventStats(OrgData)?.[0]?.eventName
                    ? "Next Event Organizing"
                    : `You have no upcoming events, Create one with juznow.com`}
                </article>
              )}
              {Access === "USER" && (
                <article className="text-center text-3xl font-extrabold mb-2">
                  {!EventStats(data)?.[0]?.event?.eventName &&
                    `You have no upcoming events`}
                  <div className="flex justify-end">
                    {EventStats(data)?.[0]?.event?.eventName && (
                      <section class="bg-orange text-white w-[100px] flex justify-center font-medium rounded text-sm">
                        Today
                      </section>
                    )}
                  </div>
                </article>
              )}
              <article>
                <div className="text-3xl font-medium mb-5">
                  {Access === "USER" && EventStats(data)?.[0]?.event?.eventName}
                  {Access === "ORGANIZER" &&
                    EventStats(OrgData)?.[0]?.eventName}
                </div>
                <div>
                  {Access === "USER" &&
                    data?.length > 0 &&
                    EventStats(data)?.[0]?.event?.eventStartDateAndTime && (
                      <div className="flex items-center mb-2 text-lg md:text-xl">
                        <img
                          src="/images/Event/dateHero.svg"
                          alt="location"
                          className="mr-2"
                          style={{ width: "20px" }}
                        />
                        {moment(
                          EventStats(data)?.[0]?.event?.eventStartDateAndTime
                        ).format("MMMM Do YYYY, HH:mm a")}
                      </div>
                    )}
                  {Access === "ORGANIZER" &&
                    OrgData?.length > 0 &&
                    EventStats(OrgData)?.[0]?.eventStartDateAndTime && (
                      <div className="flex items-center mb-2 text-lg md:text-xl">
                        <img
                          src="/images/Event/dateHero.svg"
                          alt="location"
                          style={{ width: "20px" }}
                          className="mr-2"
                        />
                        {moment(
                          EventStats(OrgData)?.[0]?.eventStartDateAndTime
                        ).format("MMMM Do YYYY, HH:mm a")}
                      </div>
                    )}
                  {Access === "USER" &&
                    data?.length > 0 &&
                    EventStats(data)?.[0]?.event?.location && (
                      <div className="flex items-center text-lg md:text-xl mb-5">
                        <img
                          src="/images/Event/locationHero.svg"
                          alt="location"
                          style={{ width: "20px" }}
                          className="mr-2"
                        />
                        {EventStats(data)?.[0]?.event?.location}
                      </div>
                    )}
                  {Access === "ORGANIZER" &&
                    OrgData?.length > 0 &&
                    EventStats(OrgData)?.[0]?.location && (
                      <div className="flex items-center text-lg md:text-xl mb-5">
                        <img
                          src="/images/Event/locationHero.svg"
                          alt="location"
                          style={{ width: "20px" }}
                          className="mr-2"
                        />
                        {EventStats(OrgData)?.[0]?.location}
                      </div>
                    )}
                </div>
                {Access === "USER" &&
                  data?.length > 0 &&
                  EventStats(data)?.[0]?.event?._id && (
                    <ToolBar
                      eventData={EventStats(data)?.[0]?.event}
                      details
                      connectionHeader={
                        data?.Tags?.includes("social")
                          ? "View Connections"
                          : "View Responses"
                      }
                      eventHome
                      detailsLink={`/event/${
                        EventStats(data)?.[0]?.event?._id
                      }`}
                      eventHomeLink={`/event/${
                        EventStats(data)?.[0]?.event?._id
                      }/event-dashboard`}
                      connections={true}
                      connectionsLink={`/event/${
                        EventStats(data)?.[0]?.event?._id
                      }/responses`}
                    />
                    // <div className="flex gap-3">
                    //   <CLButton
                    //     className="bg-white text-primary-main font-semibold rounded-full"
                    //     onClick={() =>
                    //       navigate(
                    //         `/upcoming-events/${
                    //           EventStats(data)?.[0]?.event?._id
                    //         }`
                    //       )
                    //     }
                    //   >
                    //     See Details
                    //   </CLButton>
                    //   <CLButton
                    //     className="bg-white text-primary-main font-semibold rounded-full"
                    //     onClick={() =>
                    //       navigate(
                    //         `/event/${
                    //           EventStats(data)?.[0]?.event?._id
                    //         }/event-dashboard`
                    //       )
                    //     }
                    //   >
                    //     Join event here
                    //   </CLButton>
                    // </div>
                  )}
                {Access === "ORGANIZER" && OrgData?.length > 0 && (
                  <ToolBar
                    details
                    detailsLink={`/organiser/${EventStats(OrgData)?.[0]?._id}`}
                    fr="ORGANIZER"
                  />
                  // <div className="flex gap-3">
                  //   <CLButton
                  //     className="bg-white text-primary-main font-semibold rounded-full"
                  //     onClick={() =>
                  //       navigate(`/organiser/${EventStats(OrgData)?.[0]?._id}`)
                  //     }
                  //   >
                  //     See Details
                  //   </CLButton>
                  // </div>
                )}
              </article>
            </section>
          )}
        </div>
        {/* {Access === "USER" && <UserDash />} */}
        {Access === "ORGANIZER" && <OrgDash />}
      </section>
    </Layout>
  );
};

export default Index;

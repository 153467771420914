import React, { useEffect, useState } from "react";
import { CLButton } from "components/Button";
import { useGetEventKeyValidatedQuery } from "features/events/eventSlice";
import AddAttendeForm from "./addAttendeForm";
import { useGetQRlogInQuery } from "features/user/userSlice";
import { LoginOutlined } from "@ant-design/icons";

const SelfCheckIN = ({ code, evid, hideCancel = false, type }) => {
  const [Response, setResponseValid] = useState(null);
  const [tempSkip, settempSkip] = useState(true);
  const { data, error, isLoading } = useGetEventKeyValidatedQuery({
    id: evid,
    key: code,
  });

  const {
    data: temploginData,
    error: temperror,
    isLoading: TempLoading,
    refetch: temprefetch,
  } = useGetQRlogInQuery(
    {
      userID: Response?.reservedBy?.cmtyUserld,
      eventId: evid,
      userEmail: Response?.reservedBy?.email,
    },
    { skip: tempSkip }
  );
  useEffect(() => {
    settempSkip(false);
    if (Response?.reservedBy?.cmtyUserld) {
      settempSkip(false);
      temprefetch({
        userID: Response?.reservedBy?.cmtyUserld,
        eventId: evid,
        userEmail: Response?.reservedBy?.email,
      });
    }
  }, [Response]);

  return (
    <div>
      <div className="text-center">
        {data !== "validated" && error && !isLoading && (
          <div>Key is not valid</div>
        )}
      </div>
      {data?.message === "validated" && !isLoading && !error && !Response && (
        <div>
          <AddAttendeForm
            setIsModalVisible={null}
            evid={evid}
            hideCancel={hideCancel}
            type={type}
            setResponseValid={setResponseValid}
            setResponse={() => console.log("Response")}
            bookingMethod="self"
          />
        </div>
      )}
      {Response && (
        <div className="text-center">
          {Response?.tempPassword ? (
            <div className="mt-2 text-3xl border p-2 rounded-lg">
              Successfully checked-in to this event, <br />
              <br />
              Use link below for guest access. <br />
              <br />
              <div className="flex justify-center mt-3 mb-3">
                {temploginData && (
                  <a
                    href={`${window.location.origin.toString()}/event/${evid}/event-dashboard/${
                      temploginData?.tokens?.access?.token
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <CLButton className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-8 flex items-center justify-center mb-2 w-[200px]">
                      <LoginOutlined />
                      Open Event
                    </CLButton>
                  </a>
                )}
              </div>
              You will need to validate your email later to view responses.
            </div>
          ) : (
            <div className="mt-2 text-3xl border p-2 rounded-lg">
              Checked-in Successfully! <br /> <br />
              Login & view
              <br />
              <br />
              `Running & Upcoming Events`.
              <div className="mt-10 flex justify-center">
                <a href={`${window.location.origin.toString()}/login`}>
                  <CLButton className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-8 flex items-center justify-center mb-2 w-[200px]">
                    <LoginOutlined />
                    Login
                  </CLButton>
                </a>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelfCheckIN;

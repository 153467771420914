import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { authUrl } from "config/url";

export const resetPasswordApi = createApi({
  reducerPath: "resetPasswordApi",
  baseQuery: fetchBaseQuery({
    baseUrl: authUrl,
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    resetPassword: builder.mutation({
      query: (body) => ({
        url: `auth/reset-password`,
        method: "POST",
        body,
      }),
    }),
  }),
});
export const { useResetPasswordMutation } = resetPasswordApi;

import React, { useEffect, useState } from "react";
import { useGetUserByScanQuery } from "features/user/userSlice";
import {
  useSendMatchRequestMutation,
  useSendQuickMessageMutation,
} from "features/connection/connectionSlice";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "components/Layout";
import { WechatOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { CLImage } from "components/Image";
import { CLNotification } from "components/Notification";
import { useDispatch, useSelector } from "react-redux";
import { scanedUser } from "features/auth/authSlice";
import { CLButton } from "components/Button";
import { CLForm, CLFormItem } from "components/Form";
import { CLTextArea } from "components/Input";
import { Form } from "antd";

const Index = () => {
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { uid, id } = useParams();
  const [GuestString, setGuestString] = useState(false);
  const { data, error, isLoading, isSuccess, refetch } = useGetUserByScanQuery({
    uid,
    id,
  });
  const [
    sendRequest,
    { isLoading: isSending, isSuccess: isSent, error: isError, data: isData },
  ] = useSendMatchRequestMutation();
  const [
    sendQuickMessage,
    {
      isLoading: isSendingQuick,
      isSuccess: isSentQuick,
      error: isErrorQuick,
      data: isDataQuick,
    },
  ] = useSendQuickMessageMutation();
  useEffect(() => {
    if (isSent || isError) {
      CLNotification({
        type: "success",
        description: "Successfully added to your connection list",
      });
      // setTimeout(() => {
      //   navigate(-1);
      // }, 2000);
    }
  }, [isSent, isError]);
  useEffect(() => {
    refetch({
      uid,
      id,
    });
  }, [uid, isData]);
  const onFinish = (values) => {
    let obj = {
      byUser: user?.id,
      toUser: data?.cmtyUserld,
      message: values.message,
    };
    sendQuickMessage(obj);
    form.resetFields();
  };
  useEffect(() => {
    if (isDataQuick) {
      CLNotification({
        type: "success",
        description: isDataQuick?.message,
      });
    }
  }, [isDataQuick]);
  useEffect(() => {
    if (error?.status === 400) {
      navigate("/");
    }
  }, [error]);
  return (
    <div>
      <header className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 md:px-11 overscroll-none items-center">
        <div className="flex items-center">
          <div className="flex items-center">
            <img
              src="/images/Event/backBtn.svg"
              className="cursor-pointer mr-2"
              alt="back"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div className="font-bold">Social Session</div>
        </div>
      </header>
      <section className="p-10">
        {isLoading && "Loading..."}
        {error && (
          <div className="flex justify-center">{error.data.message}</div>
        )}
        {data && (
          <div className="flex flex-col md:flex-row gap-5">
            <section className="md:basis-1/5">
              <div className="shadow-md h-[600px] bg-cover bg-center mb-10 rounded-lg">
                <div className="p-2 flex items-center gap-5">
                  {/* <img
                    src="/images/Event/backBtn.svg"
                    className="cursor-pointer"
                    alt="back"
                    onClick={() => {
                      navigate(-1);
                    }}
                  /> */}
                  {user.tempUser && (
                    <div>
                      {!GuestString && (
                        <section
                          className="bg-orange text-white w-[140px] flex justify-center font-medium rounded items-center"
                          onClick={() => setGuestString(!GuestString)}
                        >
                          Guest Mode <InfoCircleOutlined className="ml-2" />
                        </section>
                      )}
                      {GuestString && (
                        <section
                          className="text-[11px]"
                          onClick={() => setGuestString(!GuestString)}
                        >
                          You are in guest mode. Verify your email and log back
                          in for normal access.
                        </section>
                      )}
                    </div>
                  )}
                </div>
                <section>
                  <div className="text-xl font-medium">
                    <div className="p-2">
                      <CLImage
                        width={"100%"}
                        height={120}
                        src={
                          data?.profile_image?.length > 0
                            ? data?.profile_image
                            : "/images/avatarDummy.svg"
                        }
                      />
                    </div>
                  </div>
                </section>
                <div className="text-xl font-bold mb-10 text-center">
                  {data?.userAlias}
                </div>
                <div className="px-5 flex justify-between">
                  <CLButton
                    className="bg-primary-main text-[#fff] hover:!text-[#fff] rounded-full w-full h-8 flex items-center justify-center font-semibold mb-1"
                    onClick={() => {
                       sendRequest({
                         eventId: id,
                         data: {
                           reqBY: user?.id,
                           reqFOR: data?.cmtyUserld,
                           newStatus: "accepted",
                         },
                       });
                      
                      dispatch(scanedUser(null));
                    }}
                  >
                    {isData?.message === "Response Submitted"
                      ? "Connected"
                      : "Connect"}
                  </CLButton>
                </div>
                <div></div>
                {!user.tempUser && (
                  <div
                    className={`p-4 mt-[85px] bg-[#e3e3e3] ${
                      !data?.canChat && "overLoayQr"
                    }`}
                  >
                    <CLForm
                      layout="vertical"
                      form={form}
                      onFinish={onFinish}
                      autoComplete="off"
                    >
                      <CLFormItem name="message">
                        <CLTextArea
                          autoSize={{ minRows: 5, maxRows: 5 }}
                          placeholder="Send a quick message"
                        />
                      </CLFormItem>
                      <div className="flex justify-end">
                        <CLButton
                          loading={isSendingQuick}
                          className="bg-primary-main text-[#fff] hover:!text-[#fff] rounded-full w-full h-8 flex items-center justify-center font-semibold mb-1"
                          htmlType="submit"
                        >
                          <WechatOutlined />
                          <div className="ml-2">Send Message</div>
                        </CLButton>
                      </div>
                    </CLForm>
                  </div>
                )}
              </div>
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default Index;

import Layout from "components/Layout";
import { useGetEventByIdQuery } from "features/events/eventSlice";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import EventActions from "./EventActions";
import { StatusCheck } from "./utilFunction";
import EventStats from "./EventStats";
import { Tag } from "antd";
import ToolBar from "components/EventToolbar";
import { juznowUrl } from "config/url";
import { useGetMyReservationsQuery } from "features/reservations/reservationSlice";

const Index = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, error, isLoading } = useGetEventByIdQuery(id);
  const bgStyle = {
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/Event/eventHero.svg"
    })`,
  };
  const Status = StatusCheck(data?.eventStartDateAndTime, data?.eventDuration);
  const routeToBooking = () => {
    window.open(`${juznowUrl}/events/${id}`, "_blank");
  };
  const {
    data: reservationData,
    error: reservationError,
    isLoading: reservationIsLoading,
    refetch,
  } = useGetMyReservationsQuery();
  return (
    <Layout>
      <section className="p-10">
        {isLoading && "Loading..."}
        <div className="text-center text-red-act text-xl font-bold">{error && error?.data?.message}</div>
        {data && (
          <div className="flex flex-col md:flex-row gap-5">
            <section className="md:basis-4/5">
              <div
                className="shadow-md h-[328px] bg-cover bg-center mb-10 rounded-lg"
                style={bgStyle}
              >
                <div className="p-2">
                  <img
                    src="/images/Event/backBtn.svg"
                    className="cursor-pointer"
                    alt="back"
                    onClick={() => {
                      navigate("/dashboard/upcoming-events");
                    }}
                  />
                </div>
              </div>
              <div className="text-2xl font-semibold mb-2">
                {data?.eventName}
              </div>
              {data?.description && (
                <div className="mb-3">
                  <div className="font-bold">Event Details</div>
                  <section>{data?.description}</section>
                </div>
              )}
              {data?.directions && (
                <div className="mb-3">
                  <div className="font-bold">Directions</div>
                  <section>{data?.directions}</section>
                </div>
              )}
              {data?.locateUs && data?.locateUs.length > 0 && (
                <div className="mb-3">
                  <div className="font-bold">Locate Us</div>
                  <section>{data?.locateUs}</section>
                </div>
              )}
            </section>
            <section Name="md:basis-1/5">
              <section className="min-h-[328px] border rounded-lg shadow-md border-slate-300 p-3">
                <div className="text-xl font-medium mb-10">
                  <section className="flex justify-between">
                    <div>Event Overview</div>
                    <div>
                      <img
                        className="cursor-pointer"
                        onClick={() => routeToBooking()}
                        height={16}
                        width={16}
                        src="/images/Event/viewEvent.svg"
                        alt="View on Juznow.com"
                      />
                    </div>
                  </section>
                  <div>
                    <Tag color="#267c93">
                      {StatusCheck(
                        data?.eventStartDateAndTime,
                        data?.eventDuration
                      )}
                    </Tag>
                  </div>
                </div>
                <EventStats data={data} />
                {!reservationIsLoading && (
                  <section>
                    {reservationData.find((d) => {
                      return d?.event?._id === id;
                    }) && (
                      <ToolBar
                        eventData={data}
                        eventHome={Status !== "Finished"}
                        eventHomeLink={`/event/${id}/event-dashboard`}
                        connections={true}
                        connectionsLink={`/event/${id}/responses`}
                        connectionHeader={
                          data?.Tags?.includes("social")
                            ? "View Connections"
                            : "View Responses"
                        }
                      />
                    )}
                    {!reservationData.find((d) => {
                      return d?.event?._id === id;
                    }) && <EventActions data={data} id={id} />}
                  </section>
                )}
              </section>
            </section>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default Index;

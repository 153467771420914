import React from "react";
import { Collapse, Divider } from "antd";
import EventStats from "pages/EventPage/EventStats";
import moment from "moment";
import { endDate } from "pages/EventPage/utilFunction";
const { Panel } = Collapse;

const index = ({ eventName, data }) => {
  return (
    <Collapse
      defaultActiveKey={[]}
      expandIconPosition="end"
      className="w-[100%]"
    >
      <Panel header={eventName} key="1">
        <article className="mb-2">
          <div className="flex gap-5">
            {/* <img src="/images/Event/date.svg" alt="location" className="mr-2" /> */}
            <div className="ml-1 text-[11px] md:text-sm">
              {moment(data?.eventStartDateAndTime).format("lll")}
            </div>
            <div className="ml-1 mr-1 text-[11px] md:text-sm">to</div>
            <div className="ml-1 text-[11px] md:text-sm">
              {endDate(data?.eventStartDateAndTime, data?.eventDuration)}
            </div>
          </div>
        </article>
        <article>
          <div className="flex items-center">
            <img
              src="/images/Event/locationIcon.svg"
              alt="location"
              className="mr-2"
            />
            <div className="pl-1 flex text-[11px] md:text-sm">
              {data?.location}
            </div>
          </div>
        </article>
      </Panel>
    </Collapse>
  );
};

export default index;

import { CLButton } from "components/Button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReloadOutlined } from "@ant-design/icons";
import { StatusCheck } from "pages/EventPage/utilFunction";

const ToolBar = ({
  eventData,
  details = false,
  eventHome = false,
  connections = false,
  reload = false,
  detailsLink = "",
  eventHomeLink = "",
  connectionsLink = "",
  fr = "USER",
  connectionHeader = "View Connections",
}) => {
  const navigate = useNavigate();
  const CheckEventFinished = () => {
    let st = StatusCheck(
      eventData?.eventStartDateAndTime,
      eventData?.eventDuration
    );
    if (eventData?.Tags.includes("social")) {
      if (st === "Not Started Yet") {
        return true;
      } else {
        return false;
      }
    } else {
      if (st === "Finished") {
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <section className="flex gap-2 flex-wrap">
      {details && (
        <CLButton
          className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[100px] md:w-[150px]"
          onClick={() => navigate(detailsLink)}
        >
          {fr === "ORGANIZER" ? "Manage Event" : "Detail"}
        </CLButton>
      )}
      {eventHome && (
        <CLButton
          className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[100px] md:w-[150px]"
          onClick={() => navigate(eventHomeLink)}
        >
          Event Home
        </CLButton>
      )}
      {connections && (
        <CLButton
          disabled={CheckEventFinished()}
          className={`rounded-full h-10 flex items-center justify-center mb-2 w-[150px]`}
          style={{
            backgroundColor: CheckEventFinished() ? "#8b8b8b" : "#267c93",
            color: CheckEventFinished() ? "#dad9d9" : "#ffffff",
          }}
          onClick={() => navigate(connectionsLink)}
        >
          {connectionHeader}
        </CLButton>
      )}
      {reload && (
        <CLButton
          className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[150px]"
          onClick={() => window.location.reload()}
        >
          <ReloadOutlined /> Reload
        </CLButton>
      )}
    </section>
  );
};

export default ToolBar;

import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { useRouteError } from "react-router-dom";
import { CLResult } from "../../components/Result";

const ErrorPage = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  if (isAuthenticated)
    return (
      <section>
        <CLResult
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </section>
    );
  return <Navigate to="/" />;
};

export default ErrorPage;

import { CLButton } from "components/Button";
import React from "react";
import { DollarCircleOutlined, FileDoneOutlined } from "@ant-design/icons";
import { StatusCheck } from "./utilFunction";
import { juznowUrl } from "config/url";

const EventActions = ({ data, id }) => {
  const Status = StatusCheck(data?.eventStartDateAndTime, data?.eventDuration);
  const routeToBooking = () => {
    window.open(`${juznowUrl}/events/${id}`, "_blank");
  };
  return (
    <article>
      {(Status === "Not Started Yet" || Status === "Running") && (
        <CLButton
          onClick={() => routeToBooking()}
          className="bg-primary-main text-white rounded-full w-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2"
        >
          <DollarCircleOutlined />
          Event Booking
        </CLButton>
      )}
      {/* {Status === "Finished" && (
        <CLButton className="bg-ftGray text-white rounded-full w-full hover:!text-white h-10 flex items-center justify-center mb-2">
          <FileDoneOutlined />
          Event Finished
        </CLButton>
      )} */}
    </article>
  );
};

export default EventActions;

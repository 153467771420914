import Layout from "components/Layout";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  useGetEventAttendeesQuery,
  useGetEventResponseDataQuery,
} from "features/events/eventSlice";
import { CLButton } from "components/Button";
import { CLModal } from "components/Modal";
import UserResponseModal from "./userResponseModal";
import ResponseCard from "components/ResponseCard";
// import { CLSelect } from "components/Select";

const EventUserResponse = () => {
  const Navigate = useNavigate();
  const { id, uid } = useParams();
  const { data, error, isLoading, refetch } = useGetEventResponseDataQuery({
    id,
    uid,
  });
  const {
    data: listData,
    erro: listError,
    isLoading: listLoading,
  } = useGetEventAttendeesQuery({
    id,
    search: "",
  });
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const closeResponseModal = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    refetch();
  }, []);
  console.log(listData, "listData");
  return (
    <div>
      <div className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 items-center md:px-11 overscroll-none">
        {!error && !isLoading && (
          <section className="flex items-center">
            <div className="flex items-center">
              <img
                src="/images/Event/backBtn.svg"
                className="cursor-pointer mr-2"
                alt="back"
                onClick={() => {
                  Navigate(-1);
                }}
              />
            </div>

            <div className="font-bold">Response List</div>
          </section>
        )}
      </div>

      {isLoading && (
        <div className="p-10">
          <div className="flex justify-center items-center mb-2">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
          <div className="text-center text-gray-900 text-2xl font-bold">
            Loading...
          </div>
        </div>
      )}
      {error && !isLoading && (
        <div className="p-10">
          <div>
            <div>
              This Page is not accessible, Only Event organizer can access this
              page. <br />
              But you can check event detail{" "}
              <span
                className="text-[green] cursor-pointer font-bold"
                onClick={() => {
                  Navigate(`/event/${id}`);
                }}
              >
                here
              </span>
            </div>
          </div>
        </div>
      )}
      {!isLoading && !error && (
        <div className="p-10">
          <section>
            <div className="text-center text-gray-900 text-2xl font-bold mb-5">
              {data?.eventName}
            </div>
            <section className="items-center">
              <div className="text-center text-gray-900 md:text-2xl font-bold mb-5 text-[14px]">
                Responses by{" "}
                {data?.userName ??
                  listData?.find((res) => res?.reservedBy?.cmtyUserld === uid)
                    ?.reservedBy?.name}
              </div>
              <div className="flex justify-center text-gray-900 text-2xl font-bold mb-5">
                <CLButton
                  onClick={() => setIsModalVisible(true)}
                  className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[200px]"
                >
                  <PlusCircleOutlined />
                  Add Response
                </CLButton>
              </div>
            </section>
          </section>
          {data?.ResponseList?.length === 0 && (
            <div>
              <div className="text-center text-gray-900 text-2xl font-bold mb-10">
                User has not responded yet to any user.
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 gap-2 md:grid-cols-2">
            {data?.ResponseList?.map((attendee) => {
              return (
                <ResponseCard
                  attendee={attendee}
                  user={data?.userName}
                  refetch={refetch}
                />
              );
            })}
          </div>
        </div>
      )}
      <CLModal
        centered
        closable={false}
        visible={isModalVisible}
        footer={null}
        className="ffm"
        // onCancel={() => {
        //   closeResponseModal();
        // }}
      >
        <UserResponseModal
          closeResponseModal={closeResponseModal}
          responseList={data}
          userName={
            data?.userName ??
            listData?.find((res) => res?.reservedBy?.cmtyUserld === uid)
              ?.reservedBy?.name
          }
          refetchAll={refetch}
        />
      </CLModal>
    </div>
  );
};

export default EventUserResponse;

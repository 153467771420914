import { createSlice } from "@reduxjs/toolkit";
import { getCookie } from "utils/helper";
import { loginAction, loadUser } from "./authActions";
const initialState = {
  token: getCookie("cl_a"),
  isAuthenticated: null,
  user: null,
  status: "idle",
  loading: true,
  Access: "USER",
  ScanedUser: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logoutAction: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      state.Access = "USER";
    },
    setAccess: (state, action) => {
      state.Access = action.payload;
    },
    scanedUser: (state, action) => {
      state.ScanedUser = action.payload;
    },
  },
  extraReducers: {
    [loginAction.pending]: (state) => {
      state.status = "loading";
    },
    [loginAction.fulfilled]: (state, action) => {
      state.status = "idle";
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.tokens.access.token;
      state.loading = false;
    },
    [loginAction.rejected]: (state, action) => {
      state.status = "idle";
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
    },
    [loadUser.pending]: (state) => {
      state.status = "loading";
    },
    [loadUser.fulfilled]: (state, action) => {
      state.status = "idle";
      state.isAuthenticated = true;
      state.user = action.payload;
      state.loading = false;
    },
    [loadUser.rejected]: (state, action) => {
      state.status = "idle";
      state.isAuthenticated = false;
      state.user = null;
      state.loading = false;
    },
  },
});

export const { logoutAction, setAccess, scanedUser } = authSlice.actions;

export default authSlice.reducer;

import { CLTabs } from "components/Tabs";
import React, { useState } from "react";
import Attendees from "./attendees";
import SelfCheckIn from "./SelfCheckIn";
import { useParams } from "react-router-dom";

const Index = (props) => {
  const [Active, setActive] = useState("attendees");
  return (
    <div>
      <CLTabs
        defaultActiveKey={[1]}
        className="w-full ffm"
        type="line"
        onChange={(val) => {
          setActive(val);
        }}
        items={[
          // {
          //   key: "details",
          //   label: "EVENT DETAILS",
          //   children: <Details data={data} />,
          // },
          {
            key: "attendees",
            label: "ATTENDEES",
            children: <Attendees data={props.data} Active={Active} />,
          },
          {
            key: "selfCheck",
            label: "SELF CHECK-IN",
            children: <SelfCheckIn data={props.data} />,
          },
        ]}
      />
    </div>
  );
};

export default Index;

import { InfoCircleOutlined } from "@ant-design/icons";
import { Fragment } from "react";
export const JuzNowInfo = ({ keyFor }) => {
  return (
    <Fragment>
      {keyFor === "upcomming" && (
        <section>
          <div className="text-xl font-semibold text-center mb-10">
            No upcoming events. Create an event on {" "}
            <a href="https://juznow.com/" className="text-primary-main">
              juznow.com
            </a>
          </div>
        </section>
      )}
      {keyFor === "past" && (
        <section>
          <div className="text-xl font-semibold text-center mb-10">
            No events organised. Create an event{" "}
            <a href="https://juznow.com/" className="text-primary-main">
              juznow.com
            </a>
          </div>
        </section>
      )}
    </Fragment>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import { CLNotification } from "components/Notification";
import { deleteCookie, getCookie, setCookie } from "utils/helper";
import api from "../../utils/api";
export const loginAction = createAsyncThunk(
  "auth/login",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/auth/login", data);
      setCookie(
        "cl_a",
        response.data.tokens.access.token,
        response.data.tokens.access.expires * 60 * 60 * 24
      );
      setCookie(
        "cl_r",
        response.data.tokens.refresh.token,
        response.data.tokens.refresh.expires * 60 * 60 * 24
      );
      return response.data;
    } catch (error) {
      CLNotification({
        type: "error",
        description: error?.response?.data?.message ?? "Something went wrong",
      });
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// dont trigger loadUser if token is not present

export const loadUser = createAsyncThunk("auth/loadUser", async (thunkAPI) => {
  console.log("loadUser", getCookie("cl_a"));

  try {
    const response = await api.get("/auth/me");

    if (response.data) return response.data;
  } catch (error) {
    if (error.response.data.message === "Please authenticate") {
      deleteCookie("cl_a");
      deleteCookie("cl_o");
      setTimeout(() => {
        {
          window.location.replace(`/`);
        }
      }, 2000);
    }
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

import { CLQRCode } from "components/QRCode";
import React, { useState } from "react";
import { CopyOutlined } from "@ant-design/icons";
import { CLButton } from "components/Button";

const SelfCheckIn = ({ data }) => {
  const [openQR, setopenQR] = useState(false);
  const encode = (code) => {
    return btoa(code);
  };
  const copyToClipbord = (value) => {
    // copy value to clipboard
    navigator.clipboard.writeText(value);
  };
  return (
    <div className="md:flex md:gap-20">
      <section className="mb-5">
        <article
          style={{
            border: "2px solid #e2e8f0",
            padding: "1rem",
            marginBottom: "1rem",
          }}
        >
          <section className="mb-3">
            {/* <div className="text-2xl font-semibold mb-2">Self checkIn Link</div>{" "} */}
            <div style={{ wordBreak: "break-all" }} className="mb-2">
              <a
                className="text-2xl font-semibold mb-2 hyperlink"
                href={`${window.location.origin.toString()}/event/qr/${encode(
                  data?.codeCheckIn
                )}/${encode(data?._id)}`}
              >
                Self checkIn Display
              </a>
            </div>
            <div className="text-sm">
              Print or display page on tablet at event for guests to Self
              check-in
            </div>
            <div
              className="text-sm cursor-pointer font-bold"
              onClick={() => setopenQR(!openQR)}
            >
              Optionally, click for QR code to scan from tablet
            </div>
            <CLButton
              className="flex items-center mt-3 w-full p-5 rounded-full justify-center bg-white text-[#1e7d79]"
              onClick={() =>
                copyToClipbord(
                  `${window.location.origin.toString()}/event/qr/${encode(
                    data?.codeCheckIn
                  )}/${encode(data?._id)}`
                )
              }
            >
              <CopyOutlined color={"#1e7d79"} /> COPY LINK
            </CLButton>
            {openQR && (
              <section className="mb-3">
                {/* <div className="text-2xl font-semibold mb-2">
              Self checkIn Display
            </div> */}
                <CLQRCode
                  size={300}
                  value={`${window.location.origin.toString()}/event/qr/${encode(
                    data?.codeCheckIn
                  )}/${encode(data?._id)}`}
                />
              </section>
            )}
          </section>
        </article>

        <section
          style={{
            border: "2px solid #e2e8f0",
            padding: "1rem",
          }}
        >
          {/* <div>Event CheckIn Form</div> */}
          <div style={{ wordBreak: "break-all" }} className="mb-2">
            <a
              className="text-2xl font-semibold mb-2 hyperlink "
              href={`${window.location.origin.toString()}/event/check-in/${encode(
                data?.codeCheckIn
              )}/${encode(data?._id)}`}
            >
              CheckIn Form
            </a>
          </div>

          <div className="text-sm">
            Send above link to guests to checkin eg via chat
          </div>
          <CLButton
            className="flex items-center mt-3 w-full p-5 rounded-full justify-center bg-white text-[#1e7d79]"
            onClick={() =>
              copyToClipbord(
                `${window.location.origin.toString()}/event/check-in/${encode(
                  data?.codeCheckIn
                )}/${encode(data?._id)}`
              )
            }
          >
            <CopyOutlined color={"#1e7d79"} /> COPY LINK
          </CLButton>
        </section>
      </section>
    </div>
  );
};

export default SelfCheckIn;

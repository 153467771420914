import { Select, Space } from "antd";
const { Option } = Select;

export const CLSelect = ({ children, ...props }) => {
  return <Select {...props}>{children}</Select>;
};

export const CLOption = ({ children, ...props }) => {
  return <Option {...props}>{children}</Option>;
};

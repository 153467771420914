import React, { useEffect } from "react";
import Header from "components/Header";
import OrganiserView from "components/OrganiserView";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { deleteCookie, setCookie } from "utils/helper";
import { setAccess } from "features/auth/authSlice";
const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { Access } = useSelector((state) => state.auth);
  const location = useLocation();
  const global = [
    "/organiser/upcoming",
    "/organiser/past",
    "/organiser/dashboard",
  ];

  useEffect(() => {
    if (global.includes(location.pathname)) {
      setCookie("cl_o", "true", 12 * 60 * 60 * 24);
      dispatch(setAccess("ORGANIZER"));
    } else {
      deleteCookie("cl_o");
      dispatch(setAccess("USER"));
    }
  }, []);
  return (
    <section>
      <Header />
      <section className="hidden md:block">
        <div className="flex">
          {Access === "ORGANIZER" && global.includes(location.pathname) && (
            <section className="fixed">
              <OrganiserView />
            </section>
          )}
          <section
            className={
              Access === "ORGANIZER" && global.includes(location.pathname)
                ? `flex flex-col flex-1 ml-[100px]`
                : "flex flex-col flex-1"
            }
          >
            {children}
          </section>
        </div>
      </section>

      <section className="md:hidden">{children}</section>
    </section>
  );
};

export default Layout;

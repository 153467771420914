import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetEventAttendeesQuery,
  useBlockEventAttendeesMutation,
} from "features/events/eventSlice";
import { CLTable } from "components/Table";
import { CLButton } from "components/Button";
import { PlusCircleOutlined } from "@ant-design/icons";
import { CLModal } from "components/Modal";
import { CLForm } from "components/Form";
import { CLFormItem } from "components/Form";
import { CLInput } from "components/Input";
import { Form } from "antd";
import LoginQR from "./LoginQR";
import { StatusCheck } from "../utilFunction";
import AddAttendeForm from "../addAttendeForm";

const Attendees = (props) => {
  const [form] = Form.useForm();
  const [isSuccess, setisSuccess] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchString, setSearchString] = useState("");
  const { data, error, isLoading, refetch } = useGetEventAttendeesQuery({
    id,
    search: searchString,
  });
  const [
    BlockReservation,
    { isLoading: isBlockingAttendee, isSuccess: isBlockSuccess },
  ] = useBlockEventAttendeesMutation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openModal, setopenModal] = useState(false);
  const [requestedUser, setrequestedUser] = useState(null);
  const [userEmail, setuserEmail] = useState(null);
  const [userName, setUserName] = useState(null);
  const Status = StatusCheck(
    props?.data?.eventStartDateAndTime,
    props?.data?.eventDuration
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
    },
    {
      title: "External Booking",
      dataIndex: "externalBooking",
      key: "externalBooking",
    },
    // {
    //   title: "Account Status",
    //   key: "role",
    //   render: (_, record) => {
    //     return (
    //       <div>
    //         {record.bookingMethod === "guest" ? "Guest User" : "Self Checkin"}
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div className="flex gap-5">
            <CLButton
              className="w-[100px]"
              loading={isBlockingAttendee}
              onClick={() =>
                BlockReservation({
                  id,
                  userId: record.userId,
                  type: record.blocked ? "unblock" : "block",
                })
              }
            >
              {record.blocked ? "Unblock" : "Block"}
            </CLButton>
            {record.bookingMethod === "guest" ? (
              record.role === "user" ? (
                <CLButton
                  className="w-[150px]"
                  loading={isBlockingAttendee}
                  disabled={record.blocked || !record.tempPassword}
                  onClick={() => {
                    setopenModal(true);
                    setrequestedUser(record.userId);
                    setuserEmail(record.email);
                    setUserName(record.name);
                  }}
                >
                  Verified
                </CLButton>
              ) : (
                <CLButton
                  className="w-[150px]"
                  loading={isBlockingAttendee}
                  disabled={record.blocked || !record.tempPassword}
                  onClick={() => {
                    setopenModal(true);
                    setrequestedUser(record.userId);
                    setuserEmail(record.email);
                    setUserName(record.name);
                  }}
                >
                  Guest User
                </CLButton>
              )
            ) : (
              <CLButton
                className="w-[150px]"
                loading={isBlockingAttendee}
                disabled={true}
                // onClick={() => {
                //   setopenModal(true);
                //   setrequestedUser(record.userId);
                //   setuserEmail(record.email);
                // }}
              >
                Self checked in
              </CLButton>
            )}
            {props?.data?.Tags?.includes("speeddating") && (
              <CLButton
                onClick={() =>
                  navigate(`/organiser/${id}/response-list/${record?.userId}`)
                }
              >
                Responses
              </CLButton>
            )}
            {props?.data?.Tags?.includes("speeddating") && (
              <CLButton
                onClick={() =>
                  navigate(`/organiser/${id}/match-list/${record?.userId}`)
                }
              >
                Matches
              </CLButton>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (isSuccess || isBlockSuccess) {
      setIsModalVisible(false);
      form.resetFields();
      refetch();
      setisSuccess(false);
    }
    if (searchString) {
      refetch();
    }
  }, [isSuccess, isBlockSuccess, searchString]);
  const closeModal = () => {
    setopenModal(false);
    setrequestedUser(null);
    setuserEmail(null);
  };
  const findAttendee = (e) => {
    const searchValue = e.target.value;
    setSearchString(searchValue);
    if (!searchValue) {
      refetch();
    }
  };
  useEffect(() => {
    if (props.Active === "attendees") {
      refetch();
    }
  }, [props.Active]);
  return (
    <section>
      {isLoading && "Loading..."}
      {error && "Error"}
      {data && (
        <div className="ffm">
          <div className="flex justify-center mb-5 mt-5 md:justify-between flex-wrap gap-10">
            <div>
              <CLInput
                className="w-[300px] h-[40px]"
                placeholder="Search attendee using alias or name"
                onChange={(e) => findAttendee(e)}
              />
            </div>
            {Status !== "Finished" && (
              <CLButton
                onClick={() => setIsModalVisible(true)}
                className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[200px]"
              >
                <PlusCircleOutlined />
                Add Attendee
              </CLButton>
            )}
          </div>

          <div className="overflow-x-scroll">
            <CLTable
              className="hidden md:block"
              columns={columns}
              pagination={false}
              dataSource={data.map((res) => {
                return {
                  ...res.reservedBy,
                  userId: res.reservedBy.cmtyUserld,
                  name: res.reservedBy.name,
                  blocked: res.blocked,
                  tempPassword: res.tempPassword ?? null,
                  externalBooking: res.externalBooking ? "Yes" : "No",
                  alias: res?.alias ?? null,
                  role: res?.role ?? null,
                  bookingMethod: res?.bookingMethod ?? null,
                };
              })}
            />
          </div>
          <section className="md:hidden">
            {data.map((res) => (
              <section className="attendeeSmcont">
                <div className="flex gap-3 ">
                  <div className="font-bold">Name:</div>
                  <div>{res.reservedBy.name}</div>
                </div>
                <div className="flex gap-3">
                  <div className="font-bold">Alias:</div>
                  <div>{res?.alias}</div>
                </div>
                <div className="flex gap-3">
                  <div className="font-bold">External Booking:</div>
                  <div>{res.externalBooking ? "Yes" : "No"}</div>
                </div>
                <div className="flex gap-3 items-center">
                  <div className="font-bold mr-3">Action:</div>
                  <CLButton
                    className="w-[150px]"
                    loading={isBlockingAttendee}
                    onClick={() =>
                      BlockReservation({
                        id,
                        userId: res.reservedBy.cmtyUserld,
                        type: res.blocked ? "unblock" : "block",
                      })
                    }
                  >
                    {res.blocked ? "Unblock" : "Block"}
                  </CLButton>
                </div>
                <div className="flex gap-3 items-center">
                  <div className="font-bold mr-3">Access:</div>
                  {res.bookingMethod === "guest" ? (
                    <CLButton
                      className="w-[150px]"
                      loading={isBlockingAttendee}
                      disabled={res.blocked || !res.tempPassword}
                      onClick={() => {
                        setopenModal(true);
                        setrequestedUser(res?.reservedBy?.cmtyUserld);
                        setuserEmail(res?.reservedBy?.email);
                        setUserName(res?.reservedBy?.name);
                      }}
                      // disabled={true}
                    >
                      Guest User
                    </CLButton>
                  ) : (
                    <CLButton
                      className="w-[150px]"
                      loading={isBlockingAttendee}
                      disabled={true}
                    >
                      Self checked in
                    </CLButton>
                  )}
                </div>
                <div className="flex gap-3 items-center">
                  <div className="font-bold">Matches:</div>
                  <div>
                    <CLButton
                      className="w-[150px]"
                      onClick={() =>
                        navigate(
                          `/organiser/${id}/match-list/${res?.reservedBy?.cmtyUserld}`
                        )
                      }
                    >
                      Show List
                    </CLButton>
                  </div>
                </div>
                <div className="flex gap-3 items-center">
                  <div className="font-bold">Responses:</div>
                  <div>
                    <CLButton
                      className="w-[150px]"
                      onClick={() =>
                        navigate(
                          `/organiser/${id}/response-list/${res?.reservedBy?.cmtyUserld}`
                        )
                      }
                    >
                      Show Responses
                    </CLButton>
                  </div>
                </div>
              </section>
            ))}
          </section>
        </div>
      )}
      <CLModal
        centered
        closable={false}
        visible={isModalVisible}
        footer={null}
        className="ffm"
      >
        <div className="text-lg mb-5 font-semibold">Add Attendee</div>
        <AddAttendeForm
          setIsModalVisible={setIsModalVisible}
          isSuccess={isSuccess}
          setResponse={(data) => setisSuccess(data)}
          setResponseValid={() => {}}
          bookingMethod="guest"
        />
      </CLModal>
      <CLModal
        centered
        closable={false}
        visible={openModal}
        footer={null}
        className="ffm"
        onCancel={() => {
          closeModal();
        }}
      >
        <LoginQR
          userID={requestedUser}
          eventId={id}
          userEmail={userEmail}
          userName={userName}
          closeModal={closeModal}
        />
      </CLModal>
    </section>
  );
};

export default Attendees;

import Layout from "components/Layout";
import { CLTabs } from "components/Tabs";
import React from "react";
import BlockedList from "./BlockedList";
import ConnectionsList from "./ConnectionsList";

const Index = () => {
  return (
    <Layout>
      <div className="flex justify-center mt-10 text-xl">Connections</div>
      <section className="p-5">
        <CLTabs
          defaultActiveKey={[1]}
          className="w-full ffm"
          type="line"
          items={[
            {
              key: "Connections",
              label: "My Connections",
              children: <ConnectionsList />,
            },
            {
              key: "Blocked",
              label: "Blocked Connections",
              children: <BlockedList />,
            },
          ]}
        />
      </section>
    </Layout>
  );
};

export default Index;

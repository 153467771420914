import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/HomePage";
import ErrorPage from "../pages/ErrorPage";
import Login from "../pages/LoginPage";
import PrivateRoute from "./PrivateRoute";
import MyEventsPage from "../pages/MyEvents";
import EventPage from "../pages/EventPage";
import ForgetPassword from "../pages/ForgetPasswordPage";
import EventMatchesPage from "../pages/MatchListPage/eventMatchPage";
import ChatPage from "../pages/ChatPage";
import EventCheckin from "pages/EventPage/EventCheckin";
import AllEvents from "pages/AllEvents";
import UpcommingEvents from "pages/UpcommingEvents";
import EventAttended from "pages/EventsAttended";
import EditEvent from "pages/EventPage/EditEvent";
import ReservedEvent from "pages/EventPage/ReservedEvent";
import ScanUser from "pages/ScanUser";
import Inbox from "pages/Inbox";
import DashBoard from "pages/Dashboard";
import UpcommingEventsOrg from "pages/MyEvents/upcommingEvents";
import PastEventsOrg from "pages/MyEvents/pastEvents";
import QRComponent from "pages/EventSelfCheckin/QRComponent";
import Selfcheckin from "pages/EventSelfCheckin/Selfcheckin";
import EventMatchData from "pages/EventPage/OrganiserAccess/EventMatchData";
import EventUserResponse from "pages/EventPage/OrganiserAccess/EventUserResponse";
import MyProfile from "pages/MyProfile";
import SocialUser from "pages/EventPage/SocialUser";

export const RouteConfig = () => {
  return (
    <Routes>
      <Route exact path="/all-events" element={<AllEvents />} />
      <Route
        exact
        path="/my-profile"
        element={<PrivateRoute component={MyProfile} />}
      />
      {/* <Route
        exact
        path="/organiser"
        element={<PrivateRoute component={MyEventsPage} />}
      /> */}
      <Route
        exact
        path="/organiser/upcoming"
        element={<PrivateRoute component={UpcommingEventsOrg} />}
      />
      <Route
        exact
        path="/organiser/past"
        element={<PrivateRoute component={PastEventsOrg} />}
      />
      {/* <Route
        exact
        path="/dashboard"
        element={<PrivateRoute component={DashBoard} />}
      /> */}
      <Route
        exact
        path="/organiser/dashboard"
        element={<PrivateRoute component={DashBoard} />}
      />
      <Route
        exact
        path="/organiser/:id"
        element={<PrivateRoute component={EditEvent} />}
      />
      <Route
        exact
        path="/organiser/:id/match-list/:uid"
        element={<PrivateRoute component={EventMatchData} />}
      />
      <Route
        exact
        path="/organiser/:id/response-list/:uid"
        element={<PrivateRoute component={EventUserResponse} />}
      />
      <Route
        exact
        path="/dashboard/upcoming-events"
        element={<PrivateRoute component={UpcommingEvents} />}
      />
      <Route
        exact
        path="/upcoming-events/:id"
        element={<PrivateRoute component={ReservedEvent} />}
      />

      <Route
        exact
        path="/dashboard/events-attended"
        element={<PrivateRoute component={EventAttended} />}
      />
      <Route
        exact
        path="/events-attended/:id"
        element={<PrivateRoute component={ReservedEvent} />}
      />
      {/* <Route
        exact
        path="/chat/:id"
        element={<PrivateRoute component={ChatPage} />}
      /> */}
      <Route exact path="/inbox" element={<PrivateRoute component={Inbox} />} />
      <Route
        exact
        path="/event/:id/event-dashboard"
        element={<PrivateRoute component={EventCheckin} />}
      />
      <Route
        exact
        path="/event/:id/event-dashboard/:token"
        element={<EventCheckin />}
      />
      <Route exact path="/event/qr/:code/:evid?" element={<QRComponent />} />
      <Route
        exact
        path="/event/check-in/:code/:evid"
        element={<Selfcheckin />}
      />

      <Route
        exact
        path="/event/:id/event-dashboard/scanned-user/:uid"
        element={<PrivateRoute component={ScanUser} />}
      />
      <Route
        exact
        path="/event/:id/event-dashboard/social-user/:uid"
        element={<PrivateRoute component={SocialUser} />}
      />

      <Route
        exact
        path="/event/:id/responses"
        element={<PrivateRoute component={EventMatchesPage} />}
      />
      <Route
        exact
        path="/chat"
        element={<PrivateRoute component={ChatPage} />}
      />
      <Route exact path="/event/:id" element={<EventPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};

import { CLForm, CLFormItem } from "components/Form";
import { CLInput, CLInputPassword } from "components/Input";
import React from "react";
import { Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "features/auth/authActions";
import { CLButton } from "components/Button";
import { useHref, useNavigate } from "react-router-dom";
const LoginForm = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const onFinish = (values) => {
    dispatch(loginAction(values));
  };
  const { status } = useSelector((state) => state.auth);
  return (
    <CLForm
      layout="vertical"
      form={form}
      className="w-[300px] md:w-[400px] ffm"
      onFinish={onFinish}
      autoComplete="off"
    >
      <section className="mb-15">
        <section
          className="text-4xl font-semibold text-primary-main text-center mb-10 cursor-pointer"
          onClick={() => Navigate("/")}
        >
          Clublink
        </section>
        {/* <section className="text-center font-semibold text-xl mb-10">
          Sign in with Juznow Account
        </section> */}
      </section>

      <CLFormItem
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input your Email!" }]}
        onChange={(e) =>
          form.setFieldsValue({
            email: e.target.value.toLowerCase().trim(),
          })
        }
      >
        <CLInput className="rounded h-12 border-2 p-2 border-primary-main" />
      </CLFormItem>
      <CLFormItem
        label="Password"
        name="password"
        rules={[{ required: true, message: "Please input your Password!" }]}
      >
        <CLInputPassword className="rounded h-12 border-2 p-2 border-primary-main" />
      </CLFormItem>
      <section
        className="flex justify-end cursor-pointer mt-[-23px] mb-10"
        onClick={() => Navigate("/forget-password")}
      >
        Forget Password ?
      </section>

      <CLFormItem>
        <CLButton
          className="bg-primary-main text-white p-2 px-10 h-10 rounded-full font-semibold w-full hover:!bg-primary-light outline-1 "
          type="primary"
          htmlType="submit"
          loading={status === "loading" ? true : false}
        >
          Sign in with Juznow
        </CLButton>
      </CLFormItem>
      <section
        className="text-center"
        onClick={() => window.open("https://juznow.com/register", "_blank")}
      >
        Don’t have an account?{" "}
        <span className="text-primary-main font-semibold cursor-pointer hover:text-primary-light">
          Create Account
        </span>
      </section>
      <section className="text-center mt-3">
        {" "}
        Powered by{" "}
        <span
          className="text-primary-main font-semibold cursor-pointer hover:text-primary-light"
          onClick={() => window.open("https://juznow.com", "_blank")}
        >
          Juznow.com
        </span>
      </section>
    </CLForm>
  );
};

export default LoginForm;

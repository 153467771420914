import { CLButton } from "components/Button";
import { CLForm, CLFormItem } from "components/Form";
import { CLInput } from "components/Input";
import React, { useEffect } from "react";
import { Form, notification } from "antd";
import { useParams } from "react-router-dom";
import { useSelfCheckInAddMutation } from "features/events/eventSlice";

const AddAttendeForm = ({
  setIsModalVisible,
  evid,
  hideCancel,
  type,
  setResponse,
  setResponseValid,
  bookingMethod,
}) => {
  const [
    AddSelfChecking,
    { isLoading: isAddingAttendee, isSuccess, data: Response, error: isError },
  ] = useSelfCheckInAddMutation();
  const [form] = Form.useForm();
  const { id } = useParams();

  const addAttendeeHandler = (values) => {
    values.bookingMethod = bookingMethod;
    AddSelfChecking({ eventId: id ?? evid, data: values });
    form.resetFields();
  };
  useEffect(() => {
    isError &&
      notification.error({
        message: "Error",
        description: isError?.data?.message,
      });
    isError && bookingMethod === "guest" && setIsModalVisible(false);
  }, [isError]);
  useEffect(() => {
    if (isSuccess) {
      setResponse(isSuccess);
      notification.success({
        message: "Attendee added successfully",
      });
    }
  }, [isSuccess]);
  useEffect(() => {
    if (Response) {
      Response && setResponseValid(Response);
    }
  }, [Response]);

  return (
    <>
      {isError && <div>error</div>}
      <CLForm layout="vertical" onFinish={addAttendeeHandler} form={form}>
        <CLFormItem
          label="First Name"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please input first_name!",
            },
          ]}
        >
          <CLInput className="rounded h-12 border-2 p-2 border-primary-main" />
        </CLFormItem>
        <CLFormItem
          label="Last Name"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please input last_name!",
            },
          ]}
        >
          <CLInput className="rounded h-12 border-2 p-2 border-primary-main" />
        </CLFormItem>
        <CLFormItem
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input Email!",
            },
          ]}
        >
          <CLInput
            className="rounded h-12 border-2 p-2 border-primary-main"
            type="email"
          />
        </CLFormItem>
        <section className="gap-2">
          <CLButton
            htmlType="submit"
            loading={isAddingAttendee}
            className="bg-primary-main text-white rounded-full hover:!bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-full"
          >
            {type === "selfcheckin" ? `Self checkIn` : `Add Attendee`}
          </CLButton>
          {!hideCancel && (
            <CLButton
              onClick={() => {
                setIsModalVisible(false);
                form.resetFields();
              }}
              className="bg-white  text-primary-light rounded-full h-10 flex items-center justify-center mb-2 w-full"
            >
              Cancel
            </CLButton>
          )}
        </section>
      </CLForm>
    </>
  );
};

export default AddAttendeForm;

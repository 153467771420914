import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEventMatchDataQuery } from "features/events/eventSlice";
import Layout from "components/Layout";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { CLAvatar } from "components/Avatar";
import { CLButton } from "components/Button";

const EventMatchData = () => {
  const Navigate = useNavigate();
  const { id, uid } = useParams();
  const { data, error, isLoading, refetch } = useGetEventMatchDataQuery({
    id,
    uid,
  });
  const colorPalette = {
    pending: "orange",
    accepted: "green",
    rejected: "red",
  };
  useEffect(() => {
    refetch();
  }, []);
  return (
    <div>
      <div className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 items-center md:px-11 overscroll-none">
        {!error && !isLoading && (
          <section className="flex items-center">
            <div className="flex items-center">
              <img
                src="/images/Event/backBtn.svg"
                className="cursor-pointer mr-2"
                alt="back"
                onClick={() => {
                  Navigate(-1);
                }}
              />
            </div>

            <div className="font-bold">Match List</div>
          </section>
        )}
      </div>
      {isLoading && (
        <div className="p-10">
          <div className="flex justify-center items-center mb-2">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
          </div>
          <div className="text-center text-gray-900 text-2xl font-bold">
            Loading...
          </div>
        </div>
      )}
      {error && !isLoading && (
        <div className="p-10">
          <div>
            <div>
              This Page is not accessible, Only Event organizer can access this
              page. <br />
              But you can check event detail{" "}
              <span
                className="text-[green] cursor-pointer font-bold"
                onClick={() => {
                  Navigate(`/event/${id}`);
                }}
              >
                here
              </span>
            </div>
          </div>
        </div>
      )}
      {data?.FilterMatched?.length === 0 && !isLoading && (
        <div className="flex justify-center mt-4">There is no data to show</div>
      )}
      {data?.FilterMatched?.length > 0 && !isLoading && (
        <div className="p-10">
          <section>
            <div className="text-center text-gray-900 text-2xl font-bold mb-10">
              {data?.eventName}
            </div>
          </section>

          <div className="text-gray-900 md:text-2xl font-bold mb-10 text-[14px] text-center md:text-start">
            Matches For {data?.userName}
          </div>
          {data?.FilterMatched?.length === 0 && (
            <div>
              <div className="text-center text-gray-900 text-2xl font-bold mb-10">
                No matches for user,
              </div>
            </div>
          )}
          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
            {data?.FilterMatched?.map((attendee) => {
              return (
                <div className="bg-white p-4 h-[140px] text-[10px] md:text-[16px]">
                  <section className="flex justify-between bg-[#f5f5f5] p-3">
                    <article className="flex justify-between gap-2">
                      <div>
                        <CLAvatar size={48} src={"/images/avatarDummy.svg"} />
                      </div>
                      <div>
                        <div className="font-bold">
                          {attendee?.Matched?.name}
                        </div>
                        <div className="text-[gray]">
                          Alias: {attendee?.Matched?.alias ?? "N/A"}
                        </div>
                      </div>
                    </article>
                    <article>
                      <div className="cursor-pointer text-center">
                        {attendee?.Matched?.name.split(" ")[0]}
                        's Response{" "}
                      </div>
                      <div className="flex justify-end font-bold">
                        <CLButton
                          className="rounded-full mt-2"
                          style={{
                            color: colorPalette[attendee?.Matched?.status],
                            borderColor:
                              colorPalette[attendee?.Matched?.status],
                          }}
                        >
                          {attendee?.Matched?.status.charAt(0).toUpperCase() +
                            attendee?.Matched?.status.slice(1)}
                        </CLButton>
                      </div>
                    </article>
                  </section>
                  <div className="mb-2 pt-2">
                    <span
                      className="ml-2 font-bold"
                      style={{
                        color: colorPalette[attendee?.userResponse?.status],
                      }}
                    >
                      {attendee?.userResponse?.status.charAt(0).toUpperCase() +
                        attendee?.userResponse?.status.slice(1)}{" "}
                    </span>
                    <span className="font-bold">
                      by {data?.userName.split(" ")[0]}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default EventMatchData;

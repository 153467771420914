import React from "react";
import { Link } from "react-router-dom";

const OrgDash = () => {
  return (
    <div>
      <article>
        <section className="flex flex-wrap gap-5">
          <Link to="/organiser/upcoming">
            <article className="border-2 rounded-lg flex border-slate-300 hover:border-slate-500 items-center w-[314px]  gap-5 p-5 cursor-pointer">
              <div>
                <img src="/images/dashboard/eventsAttended.svg" />
              </div>
              <div>Organised Events</div>
            </article>
          </Link>

          <article
            className="border-2 rounded-lg flex border-slate-300 hover:border-slate-500 items-center w-[314px]  gap-5 p-5 cursor-pointer"
            onClick={() => window.open("https://juznow.com", "_blank")}
          >
            <div>
              <img src="/images/dashboard/upcommingEvent.svg" />
            </div>
            <div>Juznow.com</div>
          </article>
        </section>
      </article>
    </div>
  );
};

export default OrgDash;

import { CLDropDown } from "components/DropDown";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AppstoreOutlined,
  DownOutlined,
  SwapOutlined,
  QrcodeOutlined,
  WechatOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { logoutAction, setAccess } from "features/auth/authSlice";
import {
  deleteAllCookies,
  getCookie,
  setCookie,
  deleteCookie,
} from "utils/helper";
import { CLDrawer } from "components/Drawer";
import { CLDivider } from "components/Divider";
import { CLButton } from "components/Button";
import { CLModal } from "components/Modal";
import QrReader from "modern-react-qr-reader";

const Index = () => {
  const { user, Access } = useSelector((state) => state.auth);
  const [SideBarNav, setSideBarNav] = useState(false);
  const [ShowQRScan, setShowQRScan] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const Logout = () => {
    dispatch(logoutAction());
    deleteAllCookies();
    deleteCookie("cl_a");
    deleteCookie("cl_o");
    Navigate("/");
  };

  const setOrganiser = () => {
    setCookie("cl_o", "true", 12 * 60 * 60 * 24);
    dispatch(setAccess("ORGANIZER"));
    Navigate("/organiser/dashboard");
  };
  const setUser = () => {
    deleteCookie("cl_o");
    dispatch(setAccess("USER"));
    Navigate("/dashboard/upcoming-events");
  };
  const isOrganizedEvents = location.pathname.includes("organiser");
  const Useritem = () => {
    if (!getCookie("cl_o") && !isOrganizedEvents) {
      return [
        {
          key: "1",
          label: (
            <div onClick={() => Navigate("/dashboard/upcoming-events")}>
              Dashboard
            </div>
          ),
        },
        {
          key: "5",
          label: (
            <div onClick={() => setOrganiser()} className="mb-2">
              Switch to Organizer
            </div>
          ),
        },
        {
          type: "divider",
        },
        {
          key: "6",
          label: (
            <div onClick={() => Navigate("/my-profile")} className="mt-2">
              View Profile
            </div>
          ),
        },
        {
          key: "7",
          label: <div onClick={() => Logout()}>Logout</div>,
        },
      ];
    } else {
      return [
        // {
        //   key: "1",
        //   label: (
        //     <div onClick={() => Navigate("/organiser/dashboard")}>
        //       Dashboard
        //     </div>
        //   ),
        // },
        {
          key: "2",
          label: (
            <div onClick={() => setUser()} className="mb-2">
              Switch to user
            </div>
          ),
        },
        {
          type: "divider",
        },
        {
          key: "3",
          label: (
            <div onClick={() => Navigate("/my-profile")} className="mt-2">
              View Profile
            </div>
          ),
        },
        {
          key: "4",
          label: <div onClick={() => Logout()}>Logout</div>,
        },
      ];
    }
  };
  return (
    <div className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 items-center md:px-11 overscroll-none">
      <section className="flex gap-5">
        <div
          className="text-2xl font-semibold text-primary-main cursor-pointer"
          onClick={() => user && Navigate("/")}
        >
          Clublink
        </div>
      </section>
      <section className="flex gap-2">
        {user && !user.tempUser && (
          <div>
            {/* <WechatOutlined
              style={{ fontSize: 20, cursor: "pointer" }}
              onClick={() => Navigate("/chat")}
            /> */}
            <img
              src="/images/chat.svg"
              alt="chat"
              style={{ height: 28, cursor: "pointer" }}
              className="cursor-pointer"
              onClick={() => Navigate("/chat")}
            />
          </div>
        )}
        {user && Access === "ORGANIZER" && (
          <AppstoreOutlined
            className="text-xl block md:hidden"
            style={{ fontSize: 28, color: "#757575 " }}
            onClick={() => setSideBarNav(true)}
          />
        )}
        {!user && !getCookie("cl_a") && (
          <div className="flex items-center">
            <div className="mr-2">
              <QrcodeOutlined
                style={{ fontSize: 30 }}
                onClick={() => setShowQRScan(true)}
              />
            </div>

            <button
              className="bg-primary-main text-white p-2 px-10 rounded-full hover:bg-primary-light"
              onClick={() => Navigate("/login")}
            >
              Login
            </button>
          </div>
        )}

        {user && (
          <section
            className={`md:flex md:visible ${
              Access === "ORGANIZER" && "hidden"
            }`}
          >
            <div className="flex items-center">
              <div className="flex items-center cursor-pointer">
                <div className="flex items-center mr-4">
                  <CLDropDown
                    menu={{ items: Useritem() }}
                    placement="bottom"
                    arrow
                  >
                    <CLButton>
                      {user?.display_name} <DownOutlined />
                    </CLButton>
                  </CLDropDown>
                </div>
              </div>
            </div>
            <CLDrawer
              placement="left"
              className="ffm"
              closable={false}
              open={SideBarNav}
              title={
                <div className="text-2xl font-semibold text-primary-main flex justify-between">
                  <div>Clublink </div>
                  <div
                    onClick={() => setSideBarNav(false)}
                    className="cursor-pointer"
                  >
                    X
                  </div>
                </div>
              }
            >
              <section>
                {Access === "ORGANIZER" && (
                  <section>
                    {/* <CLDivider>
                      <div className="text-xl text-ftGray ffm m-5">
                        Organiser View
                      </div>
                    </CLDivider> */}
                    <div
                      className={`flex items-center  gap-5 p-2 px-10  hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                        location.pathname === "/my-profile" && "bg-navHover"
                      }`}
                      onClick={() => Navigate("/my-profile")}
                    >
                      <UserOutlined
                        style={{
                          fontSize: 32,
                          color: "#757575",
                        }}
                      />
                      <div>{user?.display_name}</div>
                    </div>
                    <div
                      className={`flex items-center  gap-5 p-2 px-10  hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                        location.pathname === "/organiser/dashboard" &&
                        "bg-navHover"
                      }`}
                      onClick={() => Navigate("/organiser/dashboard")}
                    >
                      <img
                        src="/images/dashboard/dashboard.svg"
                        alt="allEvents"
                        style={{ height: 32 }}
                      />
                      <div>{"Dashboard"}</div>
                    </div>
                    <div
                      className={`flex items-center  gap-5 p-2 px-10  hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                        location.pathname === "/organiser/upcoming" &&
                        "bg-navHover"
                      }`}
                      onClick={() => Navigate("/organiser/upcoming")}
                    >
                      <img
                        src="/images/dashboard/event.svg"
                        alt="Upcoming"
                        style={{ height: 32 }}
                      />
                      <div>{"Organised Events"}</div>
                    </div>
                    {/* <div
                      className={`flex items-center  gap-5 p-2 px-10  hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                        location.pathname === "/organiser/past" && "bg-navHover"
                      }`}
                      onClick={() => Navigate("/organiser/past")}
                    >
                      <img
                        src="/images/dashboard/event_available.svg"
                        alt="Past"
                        style={{ height: 32 }}
                      />
                      <div>{"Past Events"}</div>
                    </div> */}
                    <div
                      className={`flex items-center  gap-5 p-2 px-10  hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                        location.pathname === "/dashboard/upcoming-events" &&
                        "bg-navHover"
                      }`}
                      onClick={() => {
                        setUser();
                        Navigate("/dashboard/upcoming-events");
                        setSideBarNav(false);
                      }}
                    >
                      <SwapOutlined style={{ fontSize: 32 }} />
                      <div>{"Switch to User"}</div>
                    </div>
                  </section>
                )}

                <section className="absolute bottom-0 w-10/12">
                  <CLDivider />
                  <div
                    className="flex items-center gap-5 p-2 px-10 mb-2 text-xl text-ftGray cursor-pointer justify-center"
                    onClick={() => Logout()}
                  >
                    <img
                      src="/images/dashboard/logout.svg"
                      alt="dash"
                      style={{ height: 32 }}
                    />
                    <div>{"Logout"}</div>
                  </div>
                </section>
              </section>
            </CLDrawer>
          </section>
        )}
      </section>
      <CLModal
        visible={ShowQRScan}
        onCancel={() => setShowQRScan(false)}
        footer={null}
        closable={false}
        centered
      >
        <div className="text-2xl font-semibold text-primary-main text-center mb-2">
          Scan Guest QR Code
        </div>
        {ShowQRScan && (
          <section>
            <QrReader
              facingMode={"environment"}
              onScan={(result) => {
                if (!!result) {
                  const url = result;
                  const urlSplit = url.split("/");
                  Navigate(
                    `/${urlSplit[3]}/${urlSplit[4]}/${urlSplit[5]}/${urlSplit[6]}`
                  );
                }
              }}
            />
          </section>
        )}
        <div className="flex justify-end mt-3">
          <CLButton
            className="bg-white  text-primary-light rounded-full h-10 flex items-center justify-center mb-2 w-[120px]"
            onClick={() => setShowQRScan(false)}
          >
            Cancel
          </CLButton>
        </div>
      </CLModal>
    </div>
  );
};

export default Index;

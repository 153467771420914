import { CLSider } from "components/Layout/Sider";
import { CLPopover } from "components/PopOver";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <CLSider
        collapsible={false}
        collapsed={false}
        className="bg-white shadow-md"
        width={100}
        theme="light"
        collapsedWidth={0}
        onCollapse={() => {}}
      >
        <section className=" flex flex-col items-center pt-5 h-screen gap-5 ">
          <CLPopover content={"Dashboard"} placement="right">
            <div
              className={`flex items-center  gap-5 p-2 hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                location.pathname === "/dashboard" ? "bg-navHover" : ""
              }`}
              onClick={() => Navigate("/organiser/dashboard")}
            >
              <img
                src="/images/dashboard/dashboard.svg"
                alt="Upcoming"
                style={{ height: 32 }}
              />
            </div>
          </CLPopover>
          <CLPopover content={"Organised Events"} placement="right">
            <div
              className={`flex items-center  gap-5 p-2 hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                location.pathname === "/organiser/upcoming" ||
                location.pathname === "/organiser/past"
                  ? "bg-navHover"
                  : ""
              }`}
              onClick={() => Navigate("/organiser/upcoming")}
            >
              <img
                src="/images/dashboard/event.svg"
                alt="Upcoming"
                style={{ height: 32 }}
              />
            </div>
          </CLPopover>
          {/* <CLPopover content={"Past Events"} placement="right">
            {" "}
            <div
              className={`flex items-center  gap-5 p-2 hover:bg-navHover text-xl text-ftGray cursor-pointer ${
                location.pathname === "/organiser/past"
                  ? "bg-navHover"
                  : ""
              }`}
              onClick={() => Navigate("/organiser/past")}
            >
              <img
                src="/images/dashboard/event_available.svg"
                alt="Past"
                style={{ height: 32 }}
              />
            </div>
          </CLPopover> */}
        </section>
      </CLSider>
    </div>
  );
};

export default Index;

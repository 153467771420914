import { CLButton } from "components/Button";
import { CLForm, CLFormItem } from "components/Form";
import { CLInput } from "components/Input";
import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "features/auth/resetPasswordSlice";
import { CLNotification } from "components/Notification";

const Index = () => {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [ResetRequest, { isLoading, data, isSuccess }] =
    useResetPasswordMutation();
  const bgStyle = {
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/Login/loginHero.svg"
    })`,
  };
  const onFinish = (values) => {
    ResetRequest(values);
    form.resetFields();
  };
  useEffect(() => {
    if (isSuccess) {
      CLNotification({
        type: "success",
        description: data?.message,
      });
    }
  }, [isSuccess]);

  return (
    <section className="flex justify-between ffm">
      <aside
        className="bg-cover bg-center h-screen w-2/4 hidden md:block "
        style={bgStyle}
      ></aside>
      <aside className="flex justify-center items-center w-full md:w-2/4 h-screen ffm">
        <CLForm
          layout="vertical"
          form={form}
          className="w-[300px] md:w-[400px] ffm"
          onFinish={onFinish}
          autoComplete="off"
        >
          <section className="mb-15">
            <section
              className="text-4xl font-semibold text-primary-main text-center mb-10 cursor-pointer"
              onClick={() => Navigate("/")}
            >
              Clublink
            </section>
            <section className="text-center font-semibold text-xl mb-10">
              Forgot Password
              <section className="text-center text-sm font-light mt-1">
                Please provide your email address to set your password
              </section>
            </section>
          </section>

          <CLFormItem
            label="Email"
            name="email"
            onChange={(e) =>
              form.setFieldsValue({
                email: e.target.value.toLowerCase().trim(),
              })
            }
            rules={[
              {
                required: true,
                message: "Please input your Email!",
                type: "email",
              },
            ]}
          >
            <CLInput className="rounded h-12 border-2 p-2 border-primary-main" />
          </CLFormItem>

          <CLFormItem>
            <CLButton
              className="bg-primary-main text-white p-2 px-10 h-10 rounded-full font-semibold w-full hover:!bg-primary-light outline-1 "
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Send Reset Link
            </CLButton>
          </CLFormItem>
          <section
            className="text-center"
            onClick={() => window.open("https://juznow.com/register", "_blank")}
          >
            Don’t have an account?{" "}
            <span className="text-primary-main font-semibold cursor-pointer hover:text-primary-light">
              Create Account
            </span>
          </section>

          <section className="text-center">
            Return back to{" "}
            <span
              className="text-primary-main font-semibold cursor-pointer hover:text-primary-light"
              onClick={() => Navigate("/login")}
            >
              Sign In
            </span>
          </section>
          <section
            className="text-center mt-3
          "
          >
            {" "}
            Powered by{" "}
            <span
              className="text-primary-main font-semibold cursor-pointer hover:text-primary-light"
              onClick={() => window.open("https://juznow.com", "_blank")}
            >
              Juznow.com
            </span>
          </section>
        </CLForm>
      </aside>
    </section>
  );
};

export default Index;

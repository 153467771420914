import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "utils/helper";
import { authUrl } from "config/url";

// Define a service using a base URL and expected endpoints
// update user slice

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: authUrl,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getCookie("cl_a");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  refetchOnMountOrArgChange: true,
  endpoints: (builder) => ({
    getUserByScan: builder.query({
      query: (data) => {
        const { uid, id } = data;
        return {
          url: `users/scanned/${uid}/event/${id}`,
          method: "GET",
        };
      },
    }),
    getQRlogIn: builder.query({
      query: (data) => {
        const { userID, eventId, userEmail } = data;
        console.log("data", data);
        return {
          url: `users/temp-login/${userID}/email/${userEmail}/event/${eventId}`,
          method: "GET",
        };
      },
    }),
    addChat: builder.mutation({
      query: (data) => {
        return {
          url: `chat`,
          method: "POST",
          body: data,
        };
      },
    }),
    getUserEmail: builder.query({
      query: (id) => {
        return {
          url: `chat/user/${id}/email`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetUserByScanQuery,
  useGetQRlogInQuery,
  useAddChatMutation,
  useGetUserEmailQuery,
} = userApi;

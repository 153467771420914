import React from "react";
import { Modal } from "antd";

export const CLModal = (props) => {
  return <Modal {...props} />;
};
export const CLModalConfirm = (props) => {
  return <Modal.confirm {...props} />;
};
export const CLModalInfo = (props) => {
  return <Modal.info {...props} />;
};
export const CLModalSuccess = (props) => {
  return <Modal.success {...props} />;
};
export const CLModalError = (props) => {
  return <Modal.error {...props} />;
};

import { CLButton } from "components/Button";
import React, { useRef, useState } from "react";
import RightCircleOutlined from "@ant-design/icons/RightCircleOutlined";

const Attendeeflow = () => {
  const [stepsAt, setstepsAt] = useState(1);
  return (
    <div className="w-[300px] md:w-[800px] mt-5">
      {stepsAt === 1 && (
        <section className="main flex gap-5 mb-10">
          <div
            style={{
              border: "2px solid #267c93",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setstepsAt(2);
            }}
          >
            <div className="md:text-xl font-bold text-primary-main mb-3 text-center">
              Checkin
            </div>
            <div className="md:p-[60px]">
              <img src="/images/landing/check.svg" alt="check" />
            </div>
          </div>
          <div
            style={{
              border: "2px solid #267c93",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setstepsAt(3);
            }}
          >
            <div className="md:text-xl  font-bold text-primary-main mb-3 text-center">
              Connect
            </div>
            <div className="md:p-[60px]">
              <img src="/images/landing/connection.svg" alt="check" />
            </div>
          </div>
          <div
            style={{
              border: "2px solid #267c93",
              padding: "10px",
              cursor: "pointer",
            }}
            onClick={() => {
              setstepsAt(4);
            }}
          >
            <div className="md:text-xl  font-bold text-primary-main mb-3 text-center">
              Chat
            </div>
            <div className="md:p-[60px]">
              {" "}
              <img src="/images/landing/chatIcon.svg" alt="check" />
            </div>
          </div>
        </section>
      )}
      {stepsAt === 2 && (
        <section className="Checkin flex flex-col md:flex-row">
          <article>
            {" "}
            <div className="text-xl font-bold text-primary-main mb-3">
              Checkin
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined />
              You should have received an link or QR code to go to the checkin
              form
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined /> Checkin early - This ensures you get the
              most out of the event
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined />
              Verify account and access to Event Home
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined />
              You need to verify your account to have access to chat
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined />
              Login and go to My Events
            </div>
          </article>

          <article className="flex justify-center">
            <img src="/Checkin.png" width={256} />
          </article>
        </section>
      )}
      {stepsAt === 3 && (
        <section className="Connect flex flex-col md:flex-row">
          <article>
            {" "}
            <div className="text-xl font-bold text-primary-main mb-3">
              Connect
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined />
              On the Event home you can connect with other attendees.
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined /> Your alias for the event is your first
              name but you can change it .
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined /> Changing your alias doesn't affect
              existing connections but they will see your new alias.
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined /> Continue to connect with others for 2
              hours after event ends.
            </div>
          </article>
          <article className="flex justify-center">
            <img src="/Connect.png" width={256} />
          </article>
        </section>
      )}
      {stepsAt === 4 && (
        <section className="Chat flex flex-col md:flex-row">
          <article>
            {" "}
            <div className="text-xl font-bold text-primary-main mb-3">Chat</div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined /> You can view the results (le connections)
              from the event.
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined /> Depending on the type of event, the
              results are available immediately or two hours after the event.
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined /> On the results page, you can initiate the
              chat with your new contacts.
            </div>
            <div className="mb-2 flex items-center gap-3">
              <RightCircleOutlined />
              Then you can chat as you like
            </div>
          </article>

          <article className="flex justify-center">
            <img src="/Chat.png" width={256} />
          </article>
        </section>
      )}
      {stepsAt === 5 && (
        <section className="Done">
          <div className="text-center mb-3 text-2xl text-primary-main font-bold">
            Enjoy your event.
          </div>
          <div className="text-center">We welcome your feedback</div>
          <div className="text-center">feedback@juznow.com</div>
        </section>
      )}
      <div className="mt-5">
        <div
          className={`flex ${
            [1].includes(stepsAt) ? `justify-end` : `justify-between`
          }`}
        >
          {![1, 5].includes(stepsAt) && (
            <CLButton
              className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[100px] md:w-[150px]"
              onClick={() => {
                setstepsAt(stepsAt - 1);
              }}
            >
              Prev
            </CLButton>
          )}
          {![5].includes(stepsAt) && (
            <CLButton
              className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[100px] md:w-[150px]"
              onClick={() => {
                setstepsAt(stepsAt + 1);
              }}
            >
              Next
            </CLButton>
          )}
        </div>

        <div className="flex justify-center">
          {" "}
          {[5].includes(stepsAt) && (
            <CLButton
              className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 w-[100px] md:w-[150px]"
              onClick={() => {
                setstepsAt(1);
              }}
            >
              Done
            </CLButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default Attendeeflow;

import React from "react";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { truncateSt } from "utils/helper";
import { CLButton } from "components/Button";
import { LoginOutlined } from "@ant-design/icons";

const EventCard = ({ event, footer = true, home = false }) => {
  const { pathname } = useLocation();
  const Navigate = useNavigate();
  const bgStyle = {
    backgroundImage: `url(${
      event?.mediaList.length > 0
        ? event?.mediaList[0]
        : process.env.PUBLIC_URL + "/images/Event/eventCardHero.svg"
    })`,
    backgroundRepeat: "no-repeat",
  };
  const StatusCheck = (date, duration) => {
    const curruntDate = moment();
    const eventDate = moment(date);
    const eventEndDate = moment(date).add(duration, "hours");
    if (curruntDate.isAfter(eventDate) && curruntDate.isBefore(eventEndDate)) {
      return home ? "Ongoing" : "Attending";
    } else if (curruntDate.isAfter(eventDate)) {
      return home ? "Finished" : "Attended";
    } else {
      return "Upcoming";
    }
  };

  const dynamicRoute = () => {
    if (pathname === "/organiser/upcoming" || pathname === "/organiser/past") {
      Navigate(`/organiser/${event?._id}`);
    } else if (pathname === "/dashboard/upcoming-events") {
      Navigate(`/event/${event?._id}`);
    } else if (pathname === "/dashboard/events-attended") {
      Navigate(`/event/${event?._id}`);
    } else {
      Navigate(`/event/${event?._id}`);
    }
  };
  return (
    <div
      onClick={() => dynamicRoute()}
      key={event?._id}
      className={`shadow-md h-[565px] md:h-[565px] w-[382px] rounded cursor-pointer hover:shadow-lg  ${
        footer &&
        `ev-Card transition-all duration-300 hover:shadow-primary-light/30`
      }`}
    >
      <div
        className="h-[200px] bg-cover bg-center rounded mb-10"
        style={bgStyle}
      >
        {pathname === "/" && (
          <section className="p-2 flex justify-between">
            <section className="bg-orange text-white w-[100px] flex justify-center font-medium rounded">
              {StatusCheck(event?.eventStartDateAndTime, event?.eventDuration)}
            </section>
          </section>
        )}
      </div>
      <div className="px-5">
        <div>
          <div className="text-lg sm:text-2xl font-semibold h-[32px]">
            {truncateSt(event?.eventName, 20)}
          </div>
          <div className="mb-3 h-[48px] text-[14px]">
            {truncateSt(event?.description, 60)}
          </div>
        </div>

        <section className="flex justify-between mb-3 h-[24px]">
          {event?.Tags.includes("speeddating") && (
            <section className="bg-tags text-white  flex justify-center font-medium rounded px-2">
              Speed Dating
            </section>
          )}
          {event?.Tags.includes("social") && (
            <section className="bg-tags text-white  flex justify-center font-medium rounded px-2">
              Social Event
            </section>
          )}
        </section>
        <section className="bg-cardInfo p-5 rounded-lg text-[14px] h-[153px]">
          <div className="mb-2">
            <div className="flex items-center">
              <img
                src="/images/Event/date.svg"
                alt="location"
                className="mr-2"
              />{" "}
              <div>Date & Time</div>
            </div>
            <div className="ml-6">
              {moment(event?.eventStartDateAndTime).format(
                "MMMM Do YYYY, HH:mm a"
              )}
            </div>
          </div>
          <div>
            <div className="flex items-center">
              <img
                src="/images/Event/locationIcon.svg"
                alt="location"
                className="mr-2"
              />
              <div>Location</div>
            </div>
            <div className="ml-6"> {event?.location}</div>
          </div>
        </section>
      </div>
      {footer && (
        <section
          className="text-white w-full flex justify-center font-medium ev-Card-status"
          style={{
            backgroundColor:
              StatusCheck(
                event?.eventStartDateAndTime,
                event?.eventDuration
              ) === "Attended"
                ? "#bababa"
                : "#267c93",
          }}
        >
          {StatusCheck(event?.eventStartDateAndTime, event?.eventDuration)}
        </section>
      )}
    </div>
  );
};
export default EventCard;

import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  const history = useNavigate();
  const Auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (Auth?.token) {
      history("/dashboard/upcoming-events");
    }
  }, [Auth]);
  const bgStyle = {
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/Login/loginHero.svg"
    })`,
  };
  return (
    <section className="flex justify-between ffm">
      <aside
        className="bg-cover bg-center h-screen w-2/4 hidden md:block "
        style={bgStyle}
      ></aside>
      <aside className="flex justify-center items-center w-full md:w-2/4 h-screen">
        <LoginForm />
      </aside>
    </section>
  );
};

export default LoginPage;

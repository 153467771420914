import React from "react";
import { Link, useNavigate } from "react-router-dom";
import PrivateRoute from "Routes/PrivateRoute";
import { Tabs } from "antd";

const UserDash = ({ type }) => {
  const Navigate = useNavigate();
  const items = [
    {
      key: "1",
      label: "Upcoming",
    },
    {
      key: "2",
      label: "Attended",
    },
  ];
  const onChange = (key) => {
    if (key === "1") {
      Navigate(`/dashboard/upcoming-events`);
    } else if (key === "2") {
      Navigate(`/dashboard/events-attended`);
    }
  };
  return (
    <div className="pr-10">
      <Tabs
        centered
        size={"large"}
        activeKey={type === "upcoming" ? "1" : "2"}
        items={items}
        onChange={onChange}
        tabBarStyle={{ fontWeight: "500" }}
      />
    </div>
  );
};

export default UserDash;

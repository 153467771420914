import Layout from "components/Layout";
import React, { Fragment, useEffect } from "react";
import EventCard from "components/EventCard";
import { CLInput } from "components/Input";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useGetMyReservationsQuery } from "features/reservations/reservationSlice";
import { CheckEventStatus } from "utils/helper";
import Dashboard from "pages/Dashboard";
import UserDash from "pages/Dashboard/UserDash";

const Index = () => {
  const { data, error, isLoading, refetch } = useGetMyReservationsQuery();
  const onSearch = (value) => {
    console.log(value);
  };
  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <Dashboard />
      <div className="md:p-0 pl-10 pb-5">
        <UserDash type="past" />
      </div>
      <section className="p-10">
        {isLoading && "Loading..."}
        {error && "Error"}
        <section className="flex gap-10 flex-wrap">
          {data &&
            data?.map((event) => {
              if (
                CheckEventStatus(
                  event.event.eventStartDateAndTime,
                  event.event.eventDuration
                ) === "Completed"
              ) {
                return <EventCard event={event.event} />;
              }
            })}
        </section>
        {data?.length === 0 && !isLoading && !error && (
          <div className="text-xl font-semibold text-center mb-10">
            No events attended
          </div>
        )}
      </section>
    </div>
  );
};

export default Index;

import React, { useEffect, useState } from "react";
import Layout from "components/Layout";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useGetEventByIdQuery,
  useGetEventDashboardQuery,
} from "features/events/eventSlice";
import SpeedDatingAction from "./SpeedDatingAction";
import EventStats from "./EventStats";
import { CLButton } from "components/Button";
import {
  UserOutlined,
  LoginOutlined,
  CheckCircleOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { CLModal } from "components/Modal";
import { CLForm, CLFormItem } from "components/Form";
import { CLInput } from "components/Input";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { Collapse, Divider } from "antd";
import {
  useUpdateUserAliasInEventMutation,
  useValidateAliasInEventMutation,
} from "features/events/eventSlice";
import { CLNotification } from "components/Notification";
import { loadUser } from "features/auth/authActions";
import { getCookie, setCookie, truncateSt } from "utils/helper";
import SocialAction from "./SocialAction";
import { EditOutlined } from "@ant-design/icons";
import CollapsableInfo from "components/CollapsableInfo";
import ToolBar from "components/EventToolbar";

const EventCheckin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, token } = useParams();
  const [form] = Form.useForm();
  useEffect(() => {
    let key = getCookie("cl_a");
    if (token && (!key || key !== token)) {
      setCookie("cl_a", token, 12 * 60 * 60 * 24);
      window.location.reload();
    }
  }, [token]);
  const user = useSelector((state) => state.auth.user);
  const { data, error, isLoading, refetch } = useGetEventDashboardQuery(id);
  const [SetAlias, setSetAlias] = useState(false);
  const [updateUserAlias, { isLoading: UpdateLoading, isSuccess }] =
    useUpdateUserAliasInEventMutation();
  const {
    data: eventData,
    error: eventError,
    isLoading: eventLoading,
  } = useGetEventByIdQuery(id);
  const [
    validateAlias,
    {
      isLoading: ValidateLoading,
      isSuccess: ValidateSuccess,
      data: ValidateData,
      error: ValidateError,
    },
  ] = useValidateAliasInEventMutation();
  const updateAlias = (values) => {
    updateUserAlias({
      data: {
        name: values.alias,
        id: user.id,
      },
      eventId: id,
    });
  };
  useEffect(() => {
    if (isSuccess) {
      CLNotification({
        type: "success",
        description: "Alias Updated Successfully",
      });
      refetch(id);
      setSetAlias(false);
      // dispatch(loadUser());
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!user?.id) {
      dispatch(loadUser());
    }
  }, [user]);

  useEffect(() => {
    refetch(id);
  }, [id]);

  function Countdown({ eventStartDateAndTime }) {
    const [countdown, setCountdown] = useState("");

    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const eventTime = new Date(eventStartDateAndTime).getTime();
        const distance = eventTime - now;

        if (distance < 0) {
          clearInterval(interval);
          setCountdown("Event has started");
        } else {
          const days = Math.floor(distance / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (distance % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);

          let countdownString = "";
          if (days > 0) countdownString += days + "d ";
          if (hours > 0) countdownString += hours + "h ";
          if (minutes > 0) countdownString += minutes + "m ";
          countdownString += seconds + "s";

          setCountdown(countdownString);
        }
      }, 1000);

      return () => clearInterval(interval);
    }, [eventStartDateAndTime]);

    return (
      <div className="flex flex-col items-center justify-center h-[50vh] gap-10">
        <CollapsableInfo eventName={data?.eventName} data={data} />
        <div className="flex items-center">
          <CheckCircleOutlined
            style={{ fontSize: "24px", color: "#267c93" }}
            className="mr-2"
          />
          Access Verified
        </div>
        <div className="text-center">
          <div className="mb-3">Event Countdown</div>{" "}
          <div
            className="text-xl"
            style={{
              color: "#267c93",
              fontWeight: "bold",
              fontSize: "32px",
            }}
          >
            {countdown}
          </div>
        </div>
        <div className="mt-5">
          <ToolBar reload={true} />
        </div>
      </div>
    );
  }
  return (
    <div>
      <header className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 md:px-11 overscroll-none items-center">
        <div className="flex items-center">
          <div className="flex items-center">
            {!user?.tempUser && (
              <img
                src="/images/Event/backBtn.svg"
                className="cursor-pointer mr-2"
                alt="back"
                onClick={() => {
                  navigate(`/event/${id}`);
                }}
              />
            )}
          </div>
          <div className="font-bold">Event Home</div>
        </div>
      </header>
      <section className="p-10">
        {isLoading && "Loading..."}
        {error && (
          <div className="flex justify-center">{error.data.message}</div>
        )}
        <section>
          {new Date(data?.eventStartDateAndTime) > new Date() && (
            <Countdown eventStartDateAndTime={data.eventStartDateAndTime} />
          )}
        </section>
        {data && new Date(data?.eventStartDateAndTime) < new Date() && (
          <section>
            <section className="md:basis-1/5 w-80">
              <section className="min-h-[328px] border rounded-lg shadow-md border-slate-300 p-3">
                {!user?.id && (
                  <div className="flex justify-center items-center min-h-[328px]">
                    <CLButton
                      onClick={() => window.location.reload()}
                      className="bg-primary-main text-white rounded-full w-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2"
                    >
                      <LoginOutlined />
                      Start your event session
                    </CLButton>
                  </div>
                )}
                {user?.id && (
                  <section>
                    <div className="text-xl font-medium mb-10">
                      <div className="text-2xl font-semibold mb-2 flex items-center">
                        {/* {data?.eventName} */}
                        <CollapsableInfo
                          eventName={data?.eventName}
                          data={data}
                        />
                      </div>
                    </div>
                    {/* <EventStats data={data} /> */}
                    <div className="flex items-center justify-center font-semibold mb-2 break-all break-words">
                      Alias :{" "}
                      {data?.aliases.length > 0 ? (
                        <div className="flex items-center">
                          {truncateSt(data?.aliases[0]["name"], 20)}
                          {!data.ended && (
                            <EditOutlined
                              className="ml-2 cursor-pointer"
                              onClick={() => setSetAlias(true)}
                            />
                          )}
                        </div>
                      ) : (
                        <span className="text-red-act ml-2">
                          No Alias found
                        </span>
                      )}
                    </div>
                    {!data?.aliases.length > 0 && (
                      <CLButton
                        onClick={() => setSetAlias(true)}
                        className="bg-primary-main text-white rounded-full w-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2"
                      >
                        <UserOutlined />
                        Set Alias
                      </CLButton>
                    )}
                    {data.ended && (
                      <section>
                        <div className="text-center text-[red] text-sm">
                          Event finished
                        </div>
                        <div className="flex justify-center mt-3">
                          <ToolBar
                            connections={true}
                            eventData={eventData}
                            connectionsLink={`/event/${id}/responses`}
                            connectionHeader={
                              eventData?.Tags?.includes("social")
                                ? "View Connections"
                                : "View Responses"
                            }
                          />
                        </div>
                      </section>
                    )}
                    {!data.ended && (
                      <section>
                        {" "}
                        {data.Tags.includes("speeddating") && (
                          <div
                            className={
                              data?.aliases.length > 0 ? " " : "overLoayQr"
                            }
                          >
                            <SpeedDatingAction dataSet={data} id={id} />
                          </div>
                        )}
                        {data.Tags.includes("social") && (
                          <div
                            className={
                              data?.aliases.length > 0 ? " " : "overLayAl"
                            }
                          >
                            <SocialAction dataSet={data} id={id} />
                          </div>
                        )}
                      </section>
                    )}
                  </section>
                )}
              </section>
            </section>
          </section>
        )}
        <CLModal
          visible={SetAlias}
          onCancel={() => setSetAlias(false)}
          footer={null}
          title="Set Alias"
          centered
        >
          <CLForm onFinish={updateAlias}>
            <CLFormItem
              name="alias"
              rules={[
                {
                  required: true,
                  message: "Please add alias!",
                  whitespace: true,
                },
                {
                  max: 12,
                  message: "Alias must be less than 12 characters!",
                },
                {
                  pattern: /^[A-Za-z0-9]+$/,
                  message: "Alias can only contain alphanumeric characters!",
                },
                {
                  validator: async (_, value) => {
                    await validateAlias({
                      eventId: id,
                      data: { alias: value },
                    }).then((resp) => {
                      if (resp.error) {
                        throw new Error("Alias already taken for this event");
                      }
                    });
                  },
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <CLInput
                className="rounded h-12 border-2 p-2 border-primary-main"
                defaultValue={data?.aliases[0]?.name ?? ""}
              />
            </CLFormItem>

            <section className="flex mt-5 gap-5">
              <CLButton
                onClick={() => setSetAlias(false)}
                className="text-primary-main rounded-full w-full h-10 flex items-center justify-center mb-2"
              >
                Cancel
              </CLButton>
              <CLButton
                htmlType="submit"
                disabled={ValidateError}
                loading={ValidateLoading}
                className="bg-primary-main text-white rounded-full w-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2"
              >
                Update
              </CLButton>
            </section>
          </CLForm>
        </CLModal>
      </section>
    </div>
  );
};

export default EventCheckin;

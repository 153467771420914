import { CLAvatar } from "components/Avatar";
import { CLButton } from "components/Button";
import React from "react";

const BlockedList = ({ data }) => {

  const handleUnblock = (id) => {
  };
  return (
    <div>
      <section>
        {data &&
          data[0]?.Connection_Blocked?.map((res) => {
            return (
              <article className="flex items-center bg-[#e4e4e470] p-3 mb-2 justify-between flex-wrap gap-4">
                <div className="flex items-center gap-3">
                  <div>
                    <CLAvatar />
                  </div>
                  <div>{res?.alias}</div>
                </div>
                <div className="flex gap-3">
                  <CLButton
                    className="font-bold"
                    onClick={() => handleUnblock(res._id)}
                  >
                    Unblock
                  </CLButton>
                </div>
              </article>
            );
          })}
      </section>
    </div>
  );
};

export default BlockedList;

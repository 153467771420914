import Layout from "components/Layout";
import React, { useState } from "react";
import MatchCard from "components/MatchCard";
import { useGetMatchesByEventQuery } from "features/connection/connectionSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import EventStats from "pages/EventPage/EventStats";
import { useGetEventByIdQuery } from "features/events/eventSlice";
import { endDate } from "pages/EventPage/utilFunction";
import { CLButton } from "components/Button";
import { logoutAction } from "features/auth/authSlice";
import { deleteAllCookies, deleteCookie } from "utils/helper";
const EventMatch = () => {
  const [ShowPrivacy, setShowPrivacy] = useState(false);
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { data, loading, error, refetch } = useGetMatchesByEventQuery({
    eventId: id,
    userId: user.id,
  });
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const {
    data: eventData,
    error: eventError,
    isLoading,
  } = useGetEventByIdQuery(id);
  const Logout = () => {
    dispatch(logoutAction());
    deleteAllCookies();
    deleteCookie("cl_a");
    deleteCookie("cl_o");
    Navigate("/");
  };
  return (
    <div>
      <div className="sticky top-0 z-50 bg-white shadow-md h-[72px] flex justify-between p-2 items-center md:px-11 overscroll-none">
        {!loading && (
          <section className="flex items-center">
            <div className="flex items-center">
              <img
                src="/images/Event/backBtn.svg"
                className="cursor-pointer mr-2"
                alt="back"
                onClick={() => {
                  Navigate(-1);
                }}
              />
            </div>

            <div className="font-bold">Your matches from the event</div>
          </section>
        )}
      </div>
      {loading && <div>Loading...</div>}
      {!user.tempUser && (
        <section className="bg-white m-3">
          <div className="px-5 py-5">
            <EventStats data={eventData} showName={true} />
          </div>
          {!eventData?.Tags?.includes("social") &&
            data &&
            data?.data?.length > 0 && (
              <div className="p-5 text-[12px] text-[#808080]">
                Responses can be modified until <br />
                {endDate(
                  eventData?.eventStartDateAndTime,
                  eventData?.eventDuration + 12
                )}
              </div>
            )}
          {data && data?.data?.length === 0 && (
            <div className="flex justify-center mt-10 p-5">
              No matches found
            </div>
          )}
          {error && (
            <div className="flex justify-center mt-10 text-xl pb-[30px] text-primary-light">
              {error?.data?.message}
            </div>
          )}

          {data && data?.data?.length > 0 && (
            <section>
              <section className="p-5 flex flex-wrap gap-10 justify-center md:justify-start">
                {data?.data?.map((res) => {
                  return (
                    <MatchCard
                      data={res}
                      Uname={data?.userName}
                      refetchD={refetch}
                      eventData={eventData}
                    />
                  );
                })}
              </section>
              {!ShowPrivacy && (
                <div
                  className="flex p-10 cursor-pointer"
                  onClick={() => {
                    setShowPrivacy(true);
                  }}
                >
                  <img src="/images/privacy.svg" className="mr-2" />
                  Privacy Notice
                </div>
              )}
              {ShowPrivacy && (
                <header
                  className="p-10"
                  onClick={() => {
                    setShowPrivacy(false);
                  }}
                >
                  Due to privacy concerns we will not provide any emails here.
                  Very shortly you will be able to chat with your matches online
                  and arrange a meeting or exchange contacts as you wish
                </header>
              )}
            </section>
          )}
        </section>
      )}
      {user.tempUser && (
        <div className="flex justify-center mt-10">
          <section>
            <div className="text-center">Only Verified Customer can Chat</div>
            <div className="text-center">
              See the confirmation email from{" "}
              <a href="https://juznow.com/" className="text-primary-main">
                juznow.com
              </a>{" "}
              (our partner) and log back in to view responses.
            </div>
            <div className="flex justify-center mt-4">
              {" "}
              <CLButton
                onClick={() => {
                  Logout();
                }}
                className="bg-primary-main text-white rounded-full hover:bg-primary-light hover:!text-white h-10 flex items-center justify-center mb-2 md:w-[150px] w-[100px]"
              >
                Logout
              </CLButton>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default EventMatch;

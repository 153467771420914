import { CLButton } from "components/Button";
import { CLQRCode } from "components/QRCode";
import { useGetQRlogInQuery } from "features/user/userSlice";
import React, { Fragment, useEffect } from "react";

const LoginQR = ({ userID, eventId, userEmail, userName, closeModal }) => {
  const { data, error, isLoading } = useGetQRlogInQuery({
    userID,
    eventId,
    userEmail,
  });
  console.log(
    data &&
      `${window.location.origin.toString()}/event/${
        data.eventId
      }/event-dashboard/${data?.tokens?.access?.token}`
  );
  return (
    <Fragment>
      {isLoading && <p>Please wait QR generating...</p>}
      {error && <p>No guest access</p>}
      {!error && data && (
        <section className="text-center">
          <h1 className="text-sm">Attendee Details</h1>
          <div className="text-4xl mt-2 font-bold">{userName}</div>
          <div className="mt-8 text-sm">
            User QR code for guest access without verifying email
          </div>

          <div className="flex flex-col items-center justify-center mt-5">
            <div className="flex flex-col items-center justify-center">
              <CLQRCode
                value={`${window.location.origin.toString()}/event/${
                  data.eventId
                }/event-dashboard/${data?.tokens?.access?.token}`}
              />
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <CLButton
              onClick={() => closeModal()}
              className="bg-primary-main text-white rounded-full hover:!bg-primary-light hover:!text-white h-8 flex items-center justify-center mb-2 w-[80px]"
            >
              Close
            </CLButton>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default LoginQR;

import React from "react";
import { useParams } from "react-router-dom";
import { CLQRCode } from "components/QRCode";
import moment from "moment";
import { useGetEventByIdQuery } from "../../features/events/eventSlice";
import EventStats from "pages/EventPage/EventStats";

const QRComponent = () => {
  const { code, evid} = useParams();
  const { data, error, isLoading } = useGetEventByIdQuery(atob(evid));
  const decode = (data) => {
    return atob(data);
  };

  // console.log(decode(locate));
  console.log(data);
  return (
    <div className="flex justify-center items-center h-screen flex-col p-5 bg-white">
      <div className="text-2xl font-extrabold mb-2">{data?.eventName}</div>
      {/* <div className="text-xl">
        {moment(data?.eventStartDateAndTime).format(
          "dddd, MMM Do YYYY, h:mm a"
        )}
      </div>
      <div className="text-xl mb-5">{data?.location}</div> */}
      <EventStats data={data} />
      <div className="text-3xl font-extrabold mb-2 text-center mt-3">
        Scan for Self check-in
      </div>
      {/* <div className="hidden md:block">
        <CLQRCode
          size={300}
          value={`${window.location.origin.toString()}/event/check-in/${code}/${evid}`}
        />
      </div> */}
      <div className="mt-2">
        <CLQRCode
          size={300}
          value={`${window.location.origin.toString()}/event/check-in/${code}/${evid}`}
        />
      </div>
      {data?.locateUs !== "undefined" && data?.locateUs.length > 0 && (
        <div className="text-xl mb-10 mt-10">
          <span className="font-bold">Locate Us -</span>{" "}
          <span className="text-base">{data?.locateUs}</span>
        </div>
      )}
    </div>
  );
};

export default QRComponent;

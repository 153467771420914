import React from "react";
import { Table } from 'antd';

export const CLTable = ({ children, ...props }) => {
    return <Table {...props} />;
};





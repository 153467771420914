import { useGetEventByIdQuery } from "features/events/eventSlice";
import moment from "moment";
import EventStats from "pages/EventPage/EventStats";
import SelfCheckIN from "pages/EventPage/SelfCheckIN";
import React from "react";
import { useParams } from "react-router-dom";

const Selfcheckin = () => {
  const { code, evid } = useParams();
  const { data } = useGetEventByIdQuery(atob(evid));
  const decode = (code) => {
    return atob(code);
  };
  return (
    <div className="p-10 md:p-20">
      <div className="text-3xl font-bold mb-10 mt-10">Self checkIn</div>
      <div className="text-2xl font-extrabold mb-2">{data?.eventName}</div>
      <EventStats data={data} />

      {(!code || !evid) && <div>Loading....</div>}
      {code && evid && (
        <SelfCheckIN
          code={decode(code)}
          evid={decode(evid)}
          hideCancel={true}
          type="selfcheckin"
        />
      )}
    </div>
  );
};

export default Selfcheckin;

import React from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;
export const CLTabs = ({ children, ...props }) => {
  return <Tabs {...props}>{children}</Tabs>;
};
export const CLTabPane = ({ children, ...props }) => {
  return <TabPane {...props}>{children}</TabPane>;
};

import Layout from "components/Layout";
import React, { Fragment, useEffect } from "react";
import EventCard from "components/EventCard";
import { CLInput } from "components/Input";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import { useGetMyReservationsQuery } from "features/reservations/reservationSlice";
import { CheckEventStatus } from "utils/helper";
import { useLocation } from "react-router-dom";
import OrganiserEventCard from "components/EventCard/OrganiserEventCard";
import Dashboard from "pages/Dashboard";
import UserDash from "pages/Dashboard/UserDash";

const Index = () => {
  const { data, error, isLoading, refetch } = useGetMyReservationsQuery();
  const { pathname } = useLocation();
  useEffect(() => {
    refetch();
  }, []);
  return (
    <div>
      <Dashboard />
      <div className="md:p-0 pl-10 pb-5">
        <UserDash type="upcoming" />
      </div>
      <section className="p-10">
        {isLoading && "Loading..."}
        {error && "Error"}
        <section className="md:flex gap-10 flex-wrap">
          {data &&
            data.map((event) => {
              if (
                ["Ongoing", "Upcoming"].includes(
                  CheckEventStatus(
                    event.event.eventStartDateAndTime,
                    event.event.eventDuration
                  )
                )
              ) {
                return (
                  <section className="flex gap-10 flex-wrap mb-10 md:mb-0">
                    {pathname === "/dashboard/upcoming-events" && (
                      <EventCard event={event.event} />
                    )}
                    {(pathname === "/organiser/upcoming" ||
                      pathname === "/organiser/past") && (
                      <OrganiserEventCard event={event.event} />
                    )}
                  </section>
                );
              }
            })}
        </section>
        {data?.length === 0 && !isLoading && !error && (
          <div className="text-xl font-semibold text-center mb-10">
            You haven't registered for any events
          </div>
        )}
      </section>
    </div>
  );
};

export default Index;

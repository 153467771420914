import { juznowUrl } from "config/url";
import React from "react";

const Index = () => {
  return (
    <footer className="w-full bg-ftGray h-100 shadow-inner lg:px-[112px] lg:pt-[20px] py-5 px-5 text-white overflow-scroll overscroll-none">
      <section className="flex flex-col sm:justify-between sm:gap-[20px] sm:flex-row gap-10 ">
      </section>
      {/* <hr className="my-3" /> */}
      <section className="text-sm">
        &copy; 2023 clublink.co All rights reserved.{" "}
      </section>
    </footer>
  );
};

export default Index;
